import { useLocation, useNavigate } from "react-router-dom";
import Flag from "react-world-flags";

const LanguagePicker = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Extract current language and endpoint
  const pathSegments = location.pathname.split("/");
  const currentLang = pathSegments[1]; // 'en' or 'tr'
  const endpoint = pathSegments.slice(2).join("/"); // Keep the rest of the path

  // Change language
  const switchLanguage = (lang) => {
    window.location.replace(`/${lang}/${endpoint}`);
  };

  return (
    <div className="flex space-x-4 sm:p-4 py-2">
      {/* English Flag */}
      <button
        onClick={() => switchLanguage("en")}
        className={`p-1 w-10 rounded ${
          currentLang === "en" ? "bg-blue-50 text-white" : ""
        }`}
      >
        <Flag className="w-9" code="GB" />
      </button>

      {/* Turkish Flag */}
      <button
        onClick={() => switchLanguage("tr")}
        className={`p-1 w-10 rounded ${
          currentLang === "tr" ? "bg-red-50 text-white" : ""
        }`}
      >
        <Flag className="w-10" code="TR" />
      </button>
    </div>
  );
};

export default LanguagePicker;
