import React, { useState, useEffect, Fragment } from "react";
import {
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
  onAuthStateChanged,
} from "firebase/auth";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  addDoc,
  serverTimestamp,
  deleteDoc,
} from "firebase/firestore";
import { auth, firestore } from "../../firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import { Dialog, Menu, Transition } from "@headlessui/react";
import Timer from "../../components/Timer"; // TimerModal'ın yolu burada örnek olarak verilmiştir.
import swal from "sweetalert2";
import {
  Bars3CenterLeftIcon,
  CogIcon,
  HomeIcon,
  CalculatorIcon,
  ScaleIcon,
  XMarkIcon,
  DocumentIcon,
} from "@heroicons/react/24/outline";
import {
  BuildingOfficeIcon,
  CheckCircleIcon,
  ChevronDownIcon,
} from "@heroicons/react/20/solid";
import { useAuth } from "../../context/AuthContext";
import DoctorLayout from "../../components/layout/doctor/sidebar/Index";
import { useLanguage } from "../../services/multi-lang/context/Translator";

const placeholderImg = "/placeholder.jpeg";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Texts() {
  const { translate } = useLanguage();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { userData, setUserData } = useAuth(auth);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const contents = [
    {
      title: translate("EX_1_Title"),
      subtitle: translate("EX_1_Subtitle"),
      paragraphs: translate("EX_1_Paragraph"),
      quote: "172 " + translate("Hece"),
    },
    {
      title: translate("EX_2_Title"),
      subtitle: translate("EX_2_Subtitle"),
      paragraphs: translate("EX_2_Paragraph"),
      quote: "217 " + translate("Hece"),
    },
    {
      title: translate("EX_3_Title"),
      subtitle: translate("EX_3_Subtitle"),
      paragraphs: translate("EX_3_Paragraph"),
      quote: "216 " + translate("Hece"),
    },
    {
      title: translate("EX_4_Title"),
      subtitle: translate("EX_4_Subtitle"),
      paragraphs: translate("EX_4_Paragraph"),
      quote: "237 " + translate("Hece"),
    },
    {
      title: translate("EX_5_Title"),
      subtitle: translate("EX_5_Subtitle"),
      paragraphs: translate("EX_5_Paragraph"),
      quote: "259 " + translate("Hece"),
    },
    {
      title: translate("EX_6_Title"),
      subtitle: translate("EX_6_Subtitle"),
      paragraphs: translate("EX_6_Paragraph"),
      quote: "267 " + translate("Hece"),
    },
    {
      title: translate("EX_7_Title"),
      subtitle: translate("EX_7_Subtitle"),
      paragraphs: translate("EX_7_Paragraph"),
      quote: "297 " + translate("Hece"),
    },
    {
      title: translate("EX_8_Title"),
      subtitle: translate("EX_8_Subtitle"),
      paragraphs: translate("EX_8_Paragraph"),
      quote: "225 " + translate("Hece"),
    },
    {
      title: translate("EX_9_Title"),
      subtitle: translate("EX_9_Subtitle"),
      paragraphs: translate("EX_9_Paragraph"),
      quote: "267 " + translate("Hece"),
    },
  ];

  return (
    <DoctorLayout>
      <main className="flex-1 pb-8">
        {/* Page header */}
        <div className="bg-white shadow">
          <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
            <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
              <div className="min-w-0 flex-1">
                {/* Profile */}
                <div className="flex items-center">
                  <img
                    className="hidden h-16 w-16 rounded-full sm:block"
                    src={userData?.photoUrl || placeholderImg}
                    alt="Profile Photo"
                  />
                  <div>
                    <div className="flex items-center">
                      <img
                        className="h-16 w-16 rounded-full sm:hidden"
                        src={userData?.photoUrl || placeholderImg}
                        alt="Profile Photo"
                      />
                      <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                        Merhaba, {userData?.firstName || "User"}{" "}
                        {userData?.lastName}
                      </h1>
                    </div>
                    <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                      <dt className="sr-only">Company</dt>
                      <dd className="flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6">
                        <BuildingOfficeIcon
                          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        {userData?.workplace}
                      </dd>
                      <dt className="sr-only">Account status</dt>
                      <dd className="mt-3 flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6 sm:mt-0">
                        <CheckCircleIcon
                          className={`mr-1.5 h-5 w-5 flex-shrink-0 ${
                            userData?.paymentStatus
                              ? "text-green-400"
                              : "text-red-400"
                          }`}
                          aria-hidden="true"
                        />
                        {userData?.paymentStatus
                          ? "Payment Successful"
                          : "Payment Failed"}
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
              <div className="mt-6 flex space-x-3 md:ml-4 md:mt-0">
                <button
                  onClick={openModal}
                  type="button"
                  className="inline-flex items-center rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
                >
                  Hesap Makinesini Aç
                </button>
              </div>
            </div>
          </div>
        </div>

        <Timer isOpen={modalOpen} onClose={closeModal} />

        <div>
          {contents.map((content, index) => (
            <div
              key={index}
              className="bg-white p-8 shadow-md rounded-lg my-6 mx-5"
            >
              {/* Title */}
              <h1 className="text-2xl font-bold text-center">
                {content.title}
              </h1>
              {/* Subtitle */}
              <h2 className="text-xl font-semibold text-center mt-4">
                {content.subtitle}
              </h2>
              {/* Paragraphs */}
              <p className="text-base text-left mt-6">{content.paragraphs}</p>
              {/* Quote Area */}
              <p className="text-base text-right mt-4 italic">
                {content.quote}
              </p>
            </div>
          ))}
        </div>
      </main>
    </DoctorLayout>
  );
}
