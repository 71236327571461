import React, { useEffect, useState } from "react";
import Link from "../../services/multi-lang/components/linkWithLang";
import Layout from "../../components/layout/doctor/sidebar/Index";
import BlogManager from "../../services/cms/functions";
import moment from "moment";
import { useAuth } from "../../context/AuthContext";
import Swal from "sweetalert2";
import NavLink from "../../services/multi-lang/components/LangNavLink";
import LangNavLink from "../../services/multi-lang/components/LangNavLink";
import { useLanguage } from "../../services/multi-lang/context/Translator";
import {
  purgeUser,
  getAllUsers,
} from "../../services/data-retention/functions";

export default function Archive() {
  const { translate } = useLanguage();
  const [users, setUsers] = useState([]);
  const { currentUser } = useAuth();
  const [patients, setPatients] = useState([]);
  const { currentLanguage } = useLanguage();

  const getAllUsersFromFunc = () => {
    getAllUsers().then((data) => {
      console.log("Users", data);
      if (data) {
        setPatients(data);
      }
    });
  };

  useEffect(() => {
    getAllUsers().then((data) => {
      console.log("Users", data);
      if (data) {
        setPatients(data);
      }
    });
  }, []);

  const handlePurge = (patient) => {
    Swal.fire({
      title: "Emin misiniz?",
      text: "Bu aksiyon geri alınamaz!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Devam Et",
      cancelButtonText: "Vazgeç",
    }).then((firstResult) => {
      if (firstResult.isConfirmed) {
        Swal.fire({
          title: "Üyeniz tamamen silinecektir onaylıyor musunuz ?",
          text: "İsterseniz vazgeçebilirsiniz",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Onaylıyorum",
          cancelButtonText: "Vazgeç",
        }).then((secondResult) => {
          if (secondResult.isConfirmed) {
            purgeUser(patient).then((res) => {
              if (res) {
                Swal.fire(
                  "Deleted!",
                  "The user has been permanently removed.",
                  "success"
                );
                console.log("User Deleted", patient);
                getAllUsersFromFunc();
              } else {
                Swal.fire("Failed to Delete");
              }
            });
          }
        });
      }
    });
  };

  return (
    <Layout>
      <div className="flex items-end gap-3 justify-between w-full p-5">
        <h2 className="text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
          {translate("Veri Temizleme")}
        </h2>
      </div>

      <div className="hidden sm:block w-full">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="mt-2 flex flex-col">
            <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-teal-400 text-left text-xs font-semibold text-white uppercase tracking-wider">
                      Danışan Adı
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-teal-400 text-left text-xs font-semibold text-white uppercase tracking-wider">
                      Telefon
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-teal-400 text-left text-xs font-semibold text-white uppercase tracking-wider">
                      Bakım veren Adı/Soyadı
                    </th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-teal-400 text-left text-xs font-semibold text-white uppercase tracking-wider">
                      Aksiyon
                    </th>
                    {/* Diğer sütun başlıklarınızı buraya ekleyebilirsiniz */}
                  </tr>
                </thead>
                {/* Öğelerin dinamik olarak render edilmesi */}
                <tbody>
                  {patients.map((patient) => (
                    <tr key={patient.id}>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <Link to={`/patient/${patient.id}`}>
                          {patient.firstName} {patient.lastName}
                        </Link>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        {patient.phone}
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        {patient.caregiverName}
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <button
                          type="button"
                          className="underline underline-offset-4 text-red-500"
                          onClick={() => {
                            handlePurge(patient);
                          }}
                        >
                          Kullanıcıyı Tamamen Sil
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* Pagination kontrolü ve gösterim metni */}
              {/* <div className="flex justify-between items-center border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
                    <div className="hidden sm:block">
                      <p className="text-sm text-gray-700">
                        Showing{" "}
                        <span className="font-medium">
                          {indexOfFirstItem + 1}
                        </span>{" "}
                        to{" "}
                        <span className="font-medium">
                          {indexOfLastItem > patients.length
                            ? patients.length
                            : indexOfLastItem}
                        </span>{" "}
                        of{" "}
                        <span className="font-medium">{patients.length}</span>{" "}
                        results
                      </p>
                    </div>
                    <div className="flex gap-2">
                      {Array.from({ length: pageCount }, (_, i) => i + 1).map(
                        (number) => (
                          <button
                            key={number}
                            onClick={() => paginate(number)}
                            className={`px-4 py-2 ${
                              currentPage === number
                                ? "bg-teal-500"
                                : "bg-white"
                            } border border-gray-300 rounded-md`}
                          >
                            {number}
                          </button>
                        )
                      )}
                    </div>
                  </div> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
