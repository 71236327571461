import React, { useEffect, useState } from "react";
import { useFileExplorerStore } from "../hooks/useFileExplorerStore";
import ModalComponent from "../../../components/layout/modal/Index";
import { TextInput } from "../../../components/inputs/Index";
import FolderCard from "../components/Folder";
import FileCard from "../components/File";
import Swal from "sweetalert2";
import FilePreview from "../components/FilePreview";
import {
  ArrowLeftIcon,
  DocumentPlusIcon,
  ArrowUpTrayIcon,
} from "@heroicons/react/24/outline";
import { useLanguage } from "../../multi-lang/context/Translator";
import { useAuth } from "../../../context/AuthContext";

function FileExplorer() {
  const { translate } = useLanguage();
  const { userData } = useAuth();
  const {
    currentPath,
    folders,
    files,
    isLoading,
    error,
    loadItems,
    goToFolder,
    goBack,
    createFolder,
    createFile,
    deleteFolder,
    deleteFile,
  } = useFileExplorerStore();

  const [isCreateFolderModalOpen, setCreateFolderModalOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [isPublicCheck, setIsPublicCheck] = useState(false);

  const [previewModal, setPreviewModal] = useState(false);
  const [selectedPreview, setSelectedPreview] = useState(null);

  const [isUploadModalOpen, setUploadModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [customFileName, setCustomFileName] = useState("");
  const [previewUrl, setPreviewUrl] = useState("");

  // Load root items on initial mount
  useEffect(() => {
    loadItems("root");
  }, [loadItems]);

  const handleFolderClick = (folderName) => {
    goToFolder(folderName);
  };

  const closePreviewModal = () => {
    setPreviewModal(false);

    setTimeout(() => {
      setSelectedPreview(null);
    }, 500);
  };

  const handleCreateFolderModalToggle = () => {
    setCreateFolderModalOpen(!isCreateFolderModalOpen);
  };

  const handleCreateFolder = () => {
    let folderName = newFolderName === "" ? "Yeni Klasör" : newFolderName;

    createFolder(folderName, isPublicCheck);

    setCreateFolderModalOpen(false);
    setIsPublicCheck(false);
    setNewFolderName("");
  };

  const handleUploadModalToggle = () => {
    setUploadModalOpen(!isUploadModalOpen);
    // Reset states if closing
    if (isUploadModalOpen) {
      setSelectedFile(null);
      setCustomFileName("");
      setPreviewUrl("");
    }
  };

  const confirmDeleteOp = async (type, item) => {
    const confirmation = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    });

    if (confirmation.isConfirmed) {
      // Proceed with delete operation
      if (type === "folder") {
        deleteFolder(item);
      } else if (type === "file") {
        deleteFile(item);
      }
      Swal.fire("Deleted!", "Your file has been deleted.", "success");
      return true;
    } else {
      // User canceled delete
      Swal.fire("Cancelled", "Your file is safe :)", "info");
      return false;
    }
  };

  const togglePreview = async (file) => {
    setSelectedPreview(file);
    setPreviewModal(true);
  };

  // When user picks a file
  const handleFileSelect = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      setCustomFileName(file.name); // Default to the original file name
      // If it's an image, we can show a preview
      if (file.type.startsWith("image/")) {
        setPreviewUrl(URL.createObjectURL(file));
      } else {
        setPreviewUrl("");
      }
    }
  };

  const handleFileUpload = async () => {
    if (!selectedFile) return;

    // If customFileName differs from original, create a new File object with a new name
    const newFile =
      customFileName && customFileName !== selectedFile.name
        ? new File([selectedFile], customFileName, { type: selectedFile.type })
        : selectedFile;

    await createFile(newFile, isPublicCheck);

    // Close modal and reset
    setUploadModalOpen(false);
    setIsPublicCheck(false);
    setSelectedFile(null);
    setCustomFileName("");
    setPreviewUrl("");
  };

  const handleBreadcrumbClick = (index) => {
    const pathSegments = currentPath.split("/").slice(0, index + 1);
    const newPath = pathSegments.join("/");
    goToFolder(newPath);
  };

  return (
    <div className="p-4 bg-white m-5 rounded-md border border-1 border-gray-300 shadow-sm pt-5 pb-10">
      <h2 className="text-xl font-bold">
        File Explorer{" "}
        {currentPath && currentPath === "root"
          ? ""
          : " - " + String(currentPath).split("root/")[1]}
      </h2>
      {/* <div className="text-gray-700 text-sm mb-3 flex items-center">
        {currentPath.split("/").map((segment, index, arr) => (
          <span key={index} className="flex items-center">
            {index > 0 && <span className="mx-2">/</span>}
            <button
              onClick={() => handleBreadcrumbClick(index)}
              className={`${
                index === arr.length - 1
                  ? "text-gray-500 cursor-default"
                  : "text-blue-600 hover:underline"
              }`}
              disabled={index === arr.length - 1}
            >
              {segment === "root" ? "Home" : segment}
            </button>
          </span>
        ))}
      </div> */}

      {isLoading && <div className="text-blue-600">Loading...</div>}
      {error && <div className="text-red-500">Error: {error}</div>}

      <div className="flex items-center space-x-2 my-4">
        <button
          onClick={goBack}
          disabled={currentPath === "root"}
          className={`px-3 py-1 bg-gray-300 rounded hover:bg-gray-300 disabled:opacity-50 ${
            currentPath === "root" && "cursor-not-allowed opacity-30"
          }`}
        >
          <ArrowLeftIcon className="w-5 h-5" />
        </button>
        <button
          onClick={handleCreateFolderModalToggle}
          className="px-3 py-1 bg-teal-700 text-white rounded hover:bg-teal-600 flex items-center gap-1"
        >
          <DocumentPlusIcon className="w-5 h-5" />
          {translate("Klasör Oluştur")}
        </button>
        <button
          onClick={handleUploadModalToggle}
          className="px-3 py-1 bg-blue-200 rounded  hover:bg-blue-300 flex items-center gap-1"
        >
          <ArrowUpTrayIcon className="w-4 h-4" />
          {translate("Dosya Yükle")}
        </button>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        {/* Folders */}
        {folders.map((folder) => (
          <FolderCard
            key={folder.id}
            folder={folder}
            onClick={() => handleFolderClick(folder.name)}
            onDelete={(id) => confirmDeleteOp("folder", id)}
          />
        ))}

        {/* Files */}
        {files.map((file) => (
          <FileCard
            key={file.id}
            file={file}
            // For file clicks, you might open a preview or do something else:
            onClick={() => togglePreview(file)}
            onDelete={(id) => confirmDeleteOp("file", id)}
          />
        ))}
      </div>
      <ModalComponent
        open={isCreateFolderModalOpen}
        setOpen={setCreateFolderModalOpen}
      >
        <ModalComponent.Title>{translate("Yeni Klasör")}</ModalComponent.Title>
        <hr className="my-2" />
        <ModalComponent.Content>
          <TextInput
            value={newFolderName}
            onChange={(e) => {
              setNewFolderName(e.target.value);
            }}
            label="Klasör Adı"
          />

          {userData.isAdmin && (
            <div className="flex items-center gap-1 mt-3">
              <label className="font-bold text-gray-700" htmlFor="isPublic">
                {translate("Public File")}
              </label>
              <input
                type="checkbox"
                id="isPublic"
                name="isPublic"
                onChange={(e) => {
                  setIsPublicCheck(e.target.checked);
                }}
              />
            </div>
          )}
        </ModalComponent.Content>
        <ModalComponent.Footer>
          <button
            className="inline-flex items-center justify-center w-full px-4 py-2 text-sm font-semibold text-white bg-gray-600 rounded-md shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2 focus-visible:ring-offset-white mb-2"
            onClick={handleCreateFolder}
            type="button"
          >
            {translate("Oluştur")}
          </button>
          <button
            onClick={handleCreateFolderModalToggle}
            className="inline-flex items-center justify-center w-full px-4 py-2 text-sm font-semibold text-white bg-teal-600 rounded-md shadow-sm hover:bg-teal-700 focus-visible:outline focus-visible:ring-2 focus-visible:ring-teal-500 focus-visible:ring-offset-2 focus-visible:ring-offset-white"
            type="button"
          >
            {translate("Kapat")}
          </button>
        </ModalComponent.Footer>
      </ModalComponent>

      <ModalComponent open={isUploadModalOpen} setOpen={setUploadModalOpen}>
        <ModalComponent.Title>
          {translate("Upload New File")}
        </ModalComponent.Title>
        <hr className="my-2" />
        <ModalComponent.Content>
          {/* File input */}
          <div className="mb-4">
            <label className="block mb-1 font-medium">
              {translate("Select File")}
            </label>
            <input
              type="file"
              onChange={handleFileSelect}
              className="block w-full text-sm text-gray-600"
            />
          </div>

          {/* Only show these if a file is selected */}
          {selectedFile && (
            <>
              <TextInput
                label="Custom File Name"
                value={customFileName}
                onChange={(e) => setCustomFileName(e.target.value)}
              />

              {userData.isAdmin && (
                <div className="flex items-center gap-1 mt-3">
                  <label className="font-bold text-gray-700" htmlFor="isPublic">
                    {translate("Public File")}
                  </label>
                  <input
                    type="checkbox"
                    id="isPublic"
                    name="isPublic"
                    onChange={(e) => {
                      setIsPublicCheck(e.target.checked);
                    }}
                  />
                </div>
              )}

              <div className="mt-3 text-sm space-y-1">
                <p>
                  <strong>{translate("File Type")}:</strong> {selectedFile.type}
                </p>
                <p>
                  <strong>{translate("File Size")}:</strong> {selectedFile.size}{" "}
                  bytes
                </p>
              </div>

              {/* Preview if image */}
              {previewUrl && (
                <div className="mt-3">
                  <p className="font-medium">{translate("Preview")}:</p>
                  <img
                    src={previewUrl}
                    alt="Preview"
                    className="max-w-full h-auto mt-2 border border-gray-200"
                  />
                </div>
              )}
            </>
          )}
        </ModalComponent.Content>
        <ModalComponent.Footer>
          <button
            onClick={handleFileUpload}
            disabled={!selectedFile}
            className="inline-flex items-center justify-center w-full px-4 py-2 text-sm font-semibold text-white bg-blue-600 rounded-md shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 focus-visible:ring-offset-white mb-2"
          >
            {translate("Upload File")}
          </button>

          <button
            onClick={handleUploadModalToggle}
            className="inline-flex items-center justify-center w-full px-4 py-2 text-sm font-semibold text-white bg-gray-600 rounded-md shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2 focus-visible:ring-offset-white"
          >
            {translate("Cancel")}
          </button>
        </ModalComponent.Footer>
      </ModalComponent>

      <ModalComponent
        className="sm:max-w-6xl md:max-w-6xl max-w-6xl h-[96dvh]"
        open={previewModal}
        setOpen={setPreviewModal}
      >
        <ModalComponent.Content>
          <FilePreview
            file={selectedPreview}
            onClose={() => closePreviewModal()}
          />
        </ModalComponent.Content>
      </ModalComponent>
    </div>
  );
}

export default FileExplorer;
