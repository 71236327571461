import React, { useState, useEffect, useCallback } from "react";
import { TextareaInput, TextInput } from "../../../../components/inputs/Index";
import DoctorLayout from "../../../../components/layout/doctor/sidebar/Index";
import { useParams } from "react-router-dom";
import {
  languageService,
  emptyValues,
  emptyParagraphValues,
  emptyExerciseValues,
} from "../../functions";
import { debounce } from "lodash";
import { Tab } from "@headlessui/react";

const Item = ({ label, name, value, onChange, status }) => {
  return (
    <li
      key={name + label}
      className="overflow-hidden rounded-md bg-white p-6 shadow"
    >
      <TextareaInput
        className="resize-none"
        rows={3}
        cols={33}
        name={name}
        value={value}
        onChange={onChange}
        label={label}
      />
      {status && (
        <p
          className={`mt-2 text-sm ${
            status === "updating"
              ? "text-blue-500"
              : status === "done"
              ? "text-green-500"
              : "text-red-500"
          }`}
        >
          {status === "updating"
            ? "Updating..."
            : status === "done"
            ? "Updated successfully!"
            : "Update failed!"}
        </p>
      )}
    </li>
  );
};

const ParagraphItem = ({
  updateValue,
  mainKey,
  dictionary,
  status,
  ...props
}) => {
  const handleChange = (e) => {
    updateValue(e.target.name, e.target.value);
  };

  return (
    <li
      key={mainKey}
      className="overflow-hidden rounded-md bg-white p-6 gap-3 shadow grid-cols-2 sm:grid-cols-5 grid items-center"
    >
      <div className="col-span-2">
        <p className="text-sm font-semibold">
          {props["OriginalQuestion"] || props["Original_Title"]}
        </p>
        <p className="text-xs">
          {props["OriginalAnswer"] || props["Original_Paragraph"]}
        </p>
      </div>
      <div className="col-span-3">
        <TextInput
          name={mainKey + "_Title"}
          value={dictionary[mainKey + "_Title"]}
          onChange={handleChange}
          label="Çevirisi"
        />
        <hr />
        <TextareaInput
          className="resize-none"
          rows={3}
          cols={33}
          name={mainKey + "_Paragraph"}
          value={dictionary[mainKey + "_Paragraph"]}
          onChange={handleChange}
          label="Çevirisi"
        />
        {status && (
          <p
            className={`mt-2 text-sm ${
              status === "updating"
                ? "text-blue-500"
                : status === "done"
                ? "text-green-500"
                : "text-red-500"
            }`}
          >
            {status === "updating"
              ? "Updating..."
              : status === "done"
              ? "Updated successfully!"
              : "Update failed!"}
          </p>
        )}
      </div>
    </li>
  );
};

const ExerciseItem = ({
  updateValue,
  mainKey,
  dictionary,
  status,
  ...props
}) => {
  const handleChange = (e) => {
    updateValue(e.target.name, e.target.value);
  };

  return (
    <li
      key={mainKey}
      className="overflow-hidden rounded-md bg-white p-6 gap-3 shadow grid-cols-2 sm:grid-cols-5 grid items-center"
    >
      <div className="col-span-2">
        <p className="text-sm font-semibold">
          {props["OriginalTitle"] || props["Original_Title"]}
        </p>
        <p className="text-xs font-semibold">{props["OriginalSubtitle"]}</p>
        <p className="text-xs">
          {props["OriginalParagraph"] || props["Original_Paragraph"]}
        </p>
      </div>
      <div className="col-span-3">
        <TextInput
          name={mainKey + "_Title"}
          value={dictionary[mainKey + "_Title"]}
          onChange={handleChange}
          label="Çevirisi"
        />
        <hr />
        <TextInput
          name={mainKey + "_Subtitle"}
          value={dictionary[mainKey + "_Subtitle"]}
          onChange={handleChange}
          label="Çevirisi"
        />
        <hr />
        <TextareaInput
          className="resize-none"
          rows={3}
          cols={33}
          name={mainKey + "_Paragraph"}
          value={dictionary[mainKey + "_Paragraph"]}
          onChange={handleChange}
          label="Çevirisi"
        />
        {status && (
          <p
            className={`mt-2 text-sm ${
              status === "updating"
                ? "text-blue-500"
                : status === "done"
                ? "text-green-500"
                : "text-red-500"
            }`}
          >
            {status === "updating"
              ? "Updating..."
              : status === "done"
              ? "Updated successfully!"
              : "Update failed!"}
          </p>
        )}
      </div>
    </li>
  );
};

export default function Dictionary() {
  const { isocode } = useParams();
  const [dictionary, setDictionary] = useState({});
  const [statuses, setStatuses] = useState({}); // To track update statuses
  const [all = [], setAll] = useState(emptyValues);

  const defaultValues = {
    Words: emptyValues,
    Paragraphs: emptyParagraphValues,
    Exercises: emptyExerciseValues,
  };

  useEffect(() => {
    languageService.getDictionary(isocode).then((data) => {
      if (data) {
        //Assign
        setDictionary(data);
      }
    });
  }, [isocode]);

  // Debounced function to update dictionary value on the server
  //TODO : Error Handling
  const debouncedUpdateDictionaryValue = useCallback(
    debounce(async (key, value) => {
      setStatuses((prev) => ({ ...prev, [key]: "updating" }));

      const response = await languageService.updateDictionaryValue(
        isocode,
        key,
        value
      );

      console.log("Update response", response);

      if (!response) {
        setStatuses((prev) => ({ ...prev, [key]: "failed" }));
        // Clear the status after 1 second
        setTimeout(() => {
          setStatuses((prev) => {
            const { [key]: _, ...rest } = prev;
            return rest;
          });
        }, 1000);
      }
      setStatuses((prev) => ({ ...prev, [key]: "done" }));
      // Clear the status after 1 second
      setTimeout(() => {
        setStatuses((prev) => {
          const { [key]: _, ...rest } = prev;
          return rest;
        });
      }, 1000);
    }, 300),
    [isocode]
  );

  const updateValue = (key, value) => {
    // Update the local dictionary state
    setDictionary((prev) => ({ ...prev, [key]: value }));
    // Call the debounced server update
    debouncedUpdateDictionaryValue(key, value);
  };

  const tabs = [
    "Words",
    "Hero",
    "Paragraphs",
    "Pricing",
    "Questions & Forms",
    "Exercises",
  ];

  return (
    <DoctorLayout>
      <div className="p-5">
        <h1 className="text-xl font-semibold uppercase">
          {isocode} Dictionary
        </h1>

        <Tab.Group>
          <Tab.List className="my-2">
            {tabs.map((tab) => (
              <Tab
                onClick={() => {
                  setAll(defaultValues[tab]);
                }}
                className={({ selected }) =>
                  `text-gray-500 hover:text-cyan-700 rounded-md mx-1 py-1 px-2 sm:px-3 sm:py-2 text-sm font-medium ${
                    selected ? "bg-cyan-600 text-white hover:text-gray-200" : ""
                  }`
                }
              >
                {tab}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            <hr className="my-3" />
            <ul className="grid grid-cols-3 gap-3 my-4">
              <Tab.Panel className="w-full col-span-full gap-3 sm:grid-cols-3 grid-cols-1 grid">
                {Object.keys(all).map((key) => (
                  <Item
                    key={key}
                    label={key}
                    value={dictionary[key]}
                    onChange={(e) => {
                      updateValue(key, e.target.value);
                    }}
                    name={key}
                    status={statuses[key]} // Pass the status of each key
                  />
                ))}
              </Tab.Panel>
              <Tab.Panel>HERO</Tab.Panel>
              <Tab.Panel className="w-full col-span-full gap-3 grid-cols-1 grid">
                {Object.keys(all).map((key) => (
                  <ParagraphItem
                    key={key}
                    status={statuses[key]} // Pass the status of each key
                    mainKey={key}
                    dictionary={dictionary}
                    updateValue={updateValue}
                    {...all[key]}
                  />
                ))}
              </Tab.Panel>
              <Tab.Panel>Pricing</Tab.Panel>
              <Tab.Panel>Questions and Forms</Tab.Panel>
              <Tab.Panel className="w-full col-span-full gap-3 grid-cols-1 grid">
                {Object.keys(all).map((key) => (
                  <ExerciseItem
                    key={key}
                    status={statuses[key]} // Pass the status of each key
                    mainKey={key}
                    dictionary={dictionary}
                    updateValue={updateValue}
                    {...all[key]}
                  />
                ))}
              </Tab.Panel>
            </ul>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </DoctorLayout>
  );
}
