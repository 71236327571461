export class FolderItem {
  constructor({
    id = null,
    name = "",
    path = "",
    createdAt = null,
    updatedAt = null,
    ownerId = null,
    isPublic = false,
  } = {}) {
    this.id = id;
    this.name = name;
    this.path = path; // parent path, e.g. "root/childFolder"
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.ownerId = ownerId;
    this.isPublic = isPublic;
  }
}
