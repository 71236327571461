import React, { createContext, useState, useEffect, useContext } from "react";
import Loading from "../../../components/Loading";
import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../../firebaseConfig";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState("en"); // Default language
  const [translations, setTranslations] = useState({});
  const [loading, setLoading] = useState(true);

  // Extract the language from the pathname or fallback to browser locale
  useEffect(() => {
    const detectLanguage = () => {
      const pathname = window.location.pathname;
      const langFromPath = pathname.split("/")[1]; // Assuming /lang/... structure

      if (langFromPath) {
        return langFromPath;
      }

      // Use browser's language setting if no lang in pathname
      const browserLanguage = navigator.language || navigator.languages[0];
      // Extract ISO 639-1 code
      const ISO = browserLanguage.split("-")[0];

      //Set the url to the language
      window.history.replaceState(null, "", `/${ISO}${pathname}`);

      return browserLanguage ? ISO : "en"; // Extract ISO 639-1 code
    };

    setCurrentLanguage(detectLanguage());
  }, []);

  // Fetch translations for the current language
  useEffect(() => {
    const fetchTranslations = async () => {
      if (!currentLanguage) return;

      try {
        const docRef = doc(firestore, "translations", currentLanguage);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setTranslations(docSnap.data());
          setLoading(false);
        } else {
          console.warn(
            `No translations found for language: ${currentLanguage}`
          );
        }
      } catch (error) {
        console.error("Error fetching translations:", error);
      }
    };

    fetchTranslations();
  }, [currentLanguage]);

  // Function to get a translated value by key
  const translate = (key) => {
    return translations[key] || key; // Fallback to the key if no translation is found
  };

  return (
    <LanguageContext.Provider value={{ currentLanguage, translate }}>
      {loading ? <Loading /> : children}
    </LanguageContext.Provider>
  );
};

// Custom hook to use the LanguageContext
export const useLanguage = () => useContext(LanguageContext);
