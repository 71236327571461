import React from "react";
import { FolderIcon } from "@heroicons/react/24/outline";
import { useAuth } from "../../../context/AuthContext";

/**
 * FolderCard component
 * @param {Object} props
 * @param {Object} props.folder  - Folder data (with name, createdAt, etc.)
 * @param {Function} props.onClick - Handler when folder is clicked
 * @param {Function} [props.onDelete] - Handler when delete button is clicked
 */
export default function FolderCard({ folder, onClick, onDelete }) {
  const { currentUser } = useAuth();
  return (
    <div
      onClick={onClick}
      className="relative group flex items-start p-3 bg-white rounded-md shadow hover:shadow-lg cursor-pointer transition-shadow duration-200 border-gray-200 border border-1"
    >
      <div className="flex-shrink-0 mr-2">
        <FolderIcon className="h-6 w-6 text-yellow-500" />
      </div>
      <div className="flex-1">
        <p className="text-sm font-medium text-gray-700 overflow-hidden whitespace-pre-wrap mt-0.5 line-clamp-2">
          {String(folder.name).trim()}
        </p>
        {/* Optional: display creation date or path
        <p className="text-xs text-gray-400">{folder.path || "root"}</p> */}
      </div>

      {/* Delete button (if onDelete is provided) */}
      {folder.ownerId === currentUser.uid && onDelete && (
        <button
          onClick={(e) => {
            e.stopPropagation(); // Prevent triggering onClick
            onDelete(folder.id);
          }}
          className="invisible group-hover:visible ml-2 text-red-500 hover:text-red-600"
          title="Delete folder"
        >
          ✕
        </button>
      )}
    </div>
  );
}
