import React from "react";
import Layout from "../../components/layout/doctor/sidebar/Index";
import Documents from "../../services/cms/pages/BlogEdit";

export default function Archive() {
  return (
    <Layout>
      <Documents />
    </Layout>
  );
}
