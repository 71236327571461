import { CheckIcon } from "@heroicons/react/20/solid";
import { useLanguage } from "../../services/multi-lang/context/Translator";
import { useAuth } from "../../context/AuthContext";
import { Link } from "react-router-dom";

export default function Example() {
  const { currentUser, userData } = useAuth();
  const { translate } = useLanguage();

  const includedFeatures = [
    {
      header: "Terapi Takip Sistemi",
      content:
        "Danışanlarınızı kayıt edip takibini yapın.Kendiniz için veya danışanınız için notlar alın",
    },
    {
      header: "Danışan Paneli",
      content:
        "Danışanlarınızı sisteme kayıt ettikten sonra danışanların kendilerine giden mail ile şifre koyabilecekler ve süreçlerini takip edebilecekler",
    },
    {
      header: "Ödevlendirme",
      content:
        " Danışanlarınıza verdiğiniz ödevleri danışanlarınız kendi panelleri üzerinden takip edebilecekler.Danışanlarınız için açıklamalı fotoğraf ve videolar yükleyebileceksiniz.",
    },
    {
      header: "Ödev Bilgilendirme ve Takip Sistemi",
      content:
        "Ödev verdiğiniz danışanlar bilgilendirilecek ve ödev tesliminden önce uyarılacaklar",
    },
    {
      header: "Danışan Transfer",
      content:
        "Danışanınız istediğiniz notlar ile birlikte yeni bir terapiste transfer edilebilecek",
    },
    {
      header: "Farklı Araçlar",
      content: "Anketler ve Kekemelik Şiddeti Hesaplayıcı",
    },
  ];

  return (
    <div
      id="pricing"
      className="pb-20 shadow-2xl shadow-black  py-10 border-t border-gray-200"
    >
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="max-w-2xl sm:text-left bg-white">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Tüm İhtiyaçlarınız için Tek Paket
          </h2>
          <p className="mt-6 text-2xl pl-1 animate-pulse leading-8 font-semibold text-teal-600">
            İlk 1 Hafta Ücretsiz !
          </p>
        </div>
        <div className="mx-auto max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-10 mt-8 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">
              Aylık Abonelik
            </h3>
            <p className="mt-6 text-base leading-7 text-gray-600">
              Tüm ihtiyaçlarınızı içeren bu pakete erişmek için aylık abonelik
              satın alabilirsiniz.
            </p>

            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-teal-600">
                Pakete Dahil Olanlar
              </h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              {includedFeatures.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <CheckIcon
                    className="h-6 w-5 flex-none text-teal-600"
                    aria-hidden="true"
                  />
                  <div className="flex flex-col">
                    <p className="font-semibold">{feature.header}</p>
                    <p className="text-xs">{feature.content}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="-mt-2 p-2">
          <div className="mt-10 flex items-start justify-start w-full border border-gray-300 rounded-md pt-5">
            <div className="w-full">
              <p className="font-semibold text-teal-600 text-center animate-pulse text-2xl">
                İlk hafta <span className="font-bold">ücretsiz</span> Ardından
                Aylık
              </p>
              <p className="mt-6 mb-2 flex items-baseline justify-center gap-x-2">
                <span className="text-3xl line-through font-semibold tracking-tight text-gray-900">
                  640 TRY
                </span>
              </p>
              <p className="flex items-baseline justify-center gap-x-2">
                <span className="text-5xl font-semibold tracking-tight text-gray-900">
                  349 TRY
                </span>
              </p>
              {currentUser?.paymentStatus ? (
                <Link
                  to="dashboard"
                  className="mt-10 py-3 rounded-t-none block w-full rounded-md bg-teal-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                >
                  {translate("Abonesin")}
                </Link>
              ) : (
                <Link
                  to="billing2"
                  className="mt-10 py-3 rounded-t-none block w-full rounded-md bg-teal-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                >
                  {translate("Abone Ol")}
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
