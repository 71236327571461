import React, { useState, useEffect } from "react";
import Layout from "../../components/layout/doctor/sidebar/Index";
import { useAuth } from "../../context/AuthContext";
import Documents from "../../services/file-sys/page/DocumentPage";

export default function Archive() {
  const { userData, currentUser } = useAuth();

  return (
    <Layout>
      <Documents />
    </Layout>
  );
}
