import { create } from "zustand";

const useEditor = create((set) => ({
  editorContent: [
    {
      type: "paragraph",
      align: "left",
      children: [{ text: "Start writing..." }],
    },
  ],
  setEditorContent: (editorContent) => set({ editorContent }),
}));

export { useEditor };
