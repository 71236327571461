import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Range,
  createEditor,
  Transforms,
  Element,
  Node,
  Text,
  Editor,
} from "slate";
import { Slate, Editable, withReact, ReactEditor } from "slate-react";
import {
  FaBold,
  FaItalic,
  FaUnderline,
  FaImage,
  FaLink,
  FaAlignJustify,
  FaAlignLeft,
  FaAlignRight,
} from "react-icons/fa";
import { useEditor } from "../hooks/useEditor";

const Toolbar = ({ children }) => (
  <div className="flex space-x-2 bg-gray-100 p-2 border-b">{children}</div>
);

const ToolbarButton = ({ icon, onClick }) => (
  <button onClick={onClick} className="p-2 hover:bg-gray-300 rounded">
    {icon}
  </button>
);

const SlateEditorReact = () => {
  const editor = useMemo(() => withReact(createEditor()), []);
  const [showLinkInput, setShowLinkInput] = useState(false);
  const [linkUrl, setLinkUrl] = useState("");
  const [blockType, setBlockType] = useState("paragraph"); // Default block type
  const isFirstRender = useRef(true);
  const { editorContent, setEditorContent } = useEditor();
  const [debouncedValue, setDebouncedValue] = useState(editorContent);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const handler = setTimeout(() => {
      sessionStorage.setItem(
        "editorContent",
        JSON.stringify({ content: debouncedValue })
      );
    }, 500); // Adjust debounce delay as needed (500ms)

    return () => clearTimeout(handler); // Cleanup function to prevent excessive saves
  }, [debouncedValue]);

  const renderElement = useCallback(({ attributes, children, element }) => {
    const style = { textAlign: element.align || "left" }; // Default alignment

    switch (element.type) {
      case "image":
        return (
          <div
            {...attributes}
            style={{
              display: "flex",
              justifyContent:
                element.align === "center"
                  ? "center"
                  : element.align === "right"
                  ? "flex-end"
                  : "flex-start",
              padding: "5px",
            }}
          >
            <img className="max-w-2xl " src={element.url} alt="Inserted" />
            {children}
          </div>
        );

      case "h2":
        return (
          <h2
            {...attributes}
            className="mt-2 font-poppins text-pretty text-2xl font-semibold tracking-tight text-gray-700 sm:text-3xl"
          >
            {children}
          </h2>
        );
      case "h3":
        return (
          <h3
            {...attributes}
            className="mt-2 text-pretty text-xl font-semibold tracking-tight text-gray-600 sm:text-2xl"
          >
            {children}
          </h3>
        );
      case "paragraph":
        return (
          <p
            {...attributes}
            className="text-gray-500"
            style={{ ...style, display: "block" }}
          >
            {children}
          </p>
        );

      default:
        return (
          <p {...attributes} style={style}>
            {children}
          </p>
        );
    }
  }, []);

  const renderLeaf = useCallback(({ attributes, children, leaf }) => {
    if (leaf.bold) children = <strong>{children}</strong>;
    if (leaf.italic) children = <em>{children}</em>;
    if (leaf.underline) children = <u>{children}</u>;
    return <span {...attributes}>{children}</span>;
  }, []);

  const handleKeyDown = (event, editor) => {
    if (event.key === "Enter") {
      const { selection } = editor;
      if (!selection) return;

      const [match] = Editor.nodes(editor, {
        match: (n) => Element.isElement(n) && n.type === "paragraph",
      });

      if (match) {
        event.preventDefault();
        const [, path] = match;
        const existingAlign = match[0].align || "left"; // Preserve previous alignment

        Transforms.insertNodes(editor, {
          type: "paragraph",
          align: existingAlign, // Apply the same alignment to new lines
          children: [{ text: "" }],
        });
      }
    }
  };

  const toggleMark = (format) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  };

  const applyAlignment = (align) => {
    Transforms.setNodes(
      editor,
      { align }, // Set the align property
      {
        match: (n) => Element.isElement(n) && Editor.isBlock(editor, n),
        mode: "highest",
      }
    );
  };
  const toggleBlock = (format) => {
    const isActive = isBlockActive(editor, format);

    Transforms.setNodes(
      editor,
      { type: isActive ? "paragraph" : format }, // Toggle between paragraph and header
      { match: (n) => Element.isElement(n), mode: "highest" }
    );
  };

  const isBlockActive = (editor, format) => {
    const [match] = Editor.nodes(editor, {
      match: (n) => Element.isElement(n) && n.type === format,
    });
    return !!match;
  };

  const insertImage = (url) => {
    const image = { type: "image", url, children: [{ text: "" }] };
    Transforms.insertNodes(editor, image);
  };

  const insertLink = (url) => {
    if (!url) return;

    const { selection } = editor;
    if (!selection) return;

    const isLinkActive = Editor.nodes(editor, {
      match: (n) => Element.isElement(n) && n.type === "link",
    });

    if (isLinkActive) {
      Transforms.unwrapNodes(editor, {
        match: (n) => Element.isElement(n) && n.type === "link",
      });
    }

    const link = {
      type: "link",
      url,
      children: selection
        ? [{ text: Editor.string(editor, selection) }]
        : [{ text: url }],
    };

    if (selection && !Range.isCollapsed(selection)) {
      Transforms.wrapNodes(editor, link, { split: true });
    } else {
      Transforms.insertNodes(editor, link);
    }
  };

  const serializeContent = (nodes) => {
    if (!nodes || !Array.isArray(nodes)) return "";

    return nodes
      .map((node) => {
        if (Text.isText(node)) {
          let text = node.text;
          if (text === "") text = `<br/>`;
          if (node.bold) text = `<strong class="font-bold">${text}</strong>`;
          if (node.italic) text = `<em class="italic">${text}</em>`;
          if (node.underline) text = `<u class="underline">${text}</u>`;
          if (node.strikethrough) text = `<s class="line-through">${text}</s>`;
          return text;
        }
        console.log("Node", node);
        switch (node.type) {
          case "h2":
            return `<h2 class="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl text-${
              node.align || "left"
            }">${serializeContent(node.children)}</h2>`;
          case "h3":
            return `<h3 class="mt-2 text-pretty text-3xl font-semibold tracking-tight text-gray-800 sm:text-4xl text-${
              node.align || "left"
            }">${serializeContent(node.children)}</h3>`;
          case "blockquote":
            return `<blockquote class="border-l-4 border-gray-500 pl-4 italic">${serializeContent(
              node.children
            )}</blockquote>`;
          case "code":
            return `<pre class="bg-gray-900 text-white p-4 rounded-md"><code>${serializeContent(
              node.children
            )}</code></pre>`;
          case "bulleted-list":
            return `<ul class="list-disc pl-5">${serializeContent(
              node.children
            )}</ul>`;
          case "numbered-list":
            return `<ol class="list-decimal pl-5">${serializeContent(
              node.children
            )}</ol>`;
          case "list-item":
            return `<li class="mb-1">${serializeContent(node.children)}</li>`;
          case "link":
            return `<a href="${
              node.url
            }" target="_blank" rel="noopener noreferrer" class="text-blue-500 underline hover:text-blue-700">${serializeContent(
              node.children
            )}</a>`;
          case "image":
            return `<img src="${node.url}" alt="${
              node.alt || "Image"
            }" class="max-w-full h-auto block my-4" />`;
          case "paragraph":
          default:
            return `<p class="text-base text-gray-800 text-${
              node.align || "left"
            }">${serializeContent(node.children)}</p>`;
        }
      })
      .join("");
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      try {
        const sessionStorageContent = sessionStorage.getItem("editorContent");
        const sessionStorageContentValue = JSON.parse(sessionStorageContent);
        setEditorContent(sessionStorageContentValue.content);
        console.log("Should have rendered");
        setLoading(false);
      } catch {
        setLoading(false);
      }
    } else {
      setDebouncedValue(editorContent);
    }
    const previewDiv = document.getElementById("content-preview");
    if (previewDiv) {
      previewDiv.innerHTML = serializeContent(editorContent);
    }
  }, [editorContent]);

  return (
    <div className="my-4 mx-auto border rounded shadow-md">
      {!isLoading && (
        <Slate
          initialValue={editorContent}
          editor={editor}
          value={editorContent}
          onChange={setEditorContent}
        >
          <Toolbar>
            <ToolbarButton
              icon={<FaBold />}
              onClick={() => toggleMark("bold")}
            />
            <ToolbarButton
              icon={<FaItalic />}
              onClick={() => toggleMark("italic")}
            />
            <ToolbarButton
              icon={<FaUnderline />}
              onClick={() => toggleMark("underline")}
            />
            <ToolbarButton
              icon={<FaImage />}
              onClick={() => insertImage(prompt("Enter Image URL:"))}
            />
            <ToolbarButton
              icon={<FaLink />}
              onClick={() => {
                setShowLinkInput(true);
              }}
            />

            <div className="">
              <select
                value={blockType}
                onChange={(e) => {
                  toggleBlock(e.target.value);
                  setBlockType(e.target.value);
                }}
                className="ml-2 w-full border-none rounded min-w-28 bg-gray-100"
              >
                <option value="paragraph">Paragraph</option>
                <option value="h2">Heading 1</option>
                <option value="h3">Heading 2</option>
              </select>
            </div>

            <ToolbarButton
              icon={<FaAlignLeft />}
              onClick={() => applyAlignment("left")}
            />
            <ToolbarButton
              icon={<FaAlignJustify />}
              onClick={() => applyAlignment("center")}
            />
            <ToolbarButton
              icon={<FaAlignRight />}
              onClick={() => applyAlignment("right")}
            />
          </Toolbar>
          {showLinkInput && (
            <div className="flex space-x-2 p-2">
              <input
                type="text"
                value={linkUrl}
                onChange={(e) => setLinkUrl(e.target.value)}
                className="border p-1 rounded w-full"
                placeholder="Enter URL"
              />
              <button
                onClick={() => {
                  insertLink(linkUrl);
                  setShowLinkInput(false);
                  setLinkUrl("");
                }}
                className="bg-blue-500 text-white px-2 py-1 rounded"
              >
                Insert
              </button>
            </div>
          )}
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            className="p-3 focus:outline-none focus:ring-0 focus:border-transparent"
            onKeyDown={(event) => handleKeyDown(event, editor)}
          />
        </Slate>
      )}
    </div>
  );
};

const Image = ({ attributes, children, element }) => (
  <div {...attributes} className="flex justify-center p-2">
    <img src={element.url} alt="Inserted" className="max-w-full h-auto" />
    {children}
  </div>
);

const Link = ({ attributes, children, element }) => (
  <a
    {...attributes}
    href={element.url}
    className="text-blue-500 underline"
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </a>
);

const DefaultElement = ({ attributes, children, element }) => {
  return (
    <p {...attributes} style={{ textAlign: element.align || "left" }}>
      {children}
    </p>
  );
};

const isMarkActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => Text.isText(n) && n[format] === true,
    mode: "all",
  });
  return !!match;
};

export default SlateEditorReact;
