import React, { useState, useRef, useEffect } from "react";
import { Tab, Transition } from "@headlessui/react";
import {
  CalendarDaysIcon,
  DocumentIcon,
  BuildingLibraryIcon,
  ServerIcon,
} from "@heroicons/react/24/outline";

function MyTabs() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const tabRefs = useRef([]);
  const listRef = useRef(null);
  const [indicatorStyles, setIndicatorStyles] = useState({ height: 0, top: 0 });

  const tabs = [
    {
      icon: <DocumentIcon className="w-9 text-inherit h-9" />,
      title: "Dökümantasyon Araçları",
      text: "Not tutmanızı ve dokümantasyonunuzu kolaylaştırın.",
      content: <img className="rounded-lg" src="/docum.png" />,
    },
    {
      icon: <BuildingLibraryIcon className="w-9 text-inherit h-9" />,
      title: "Güvenli Ödeme & Üyelik",
      text: "Kolay, Hızlı ve Dijital.",
      content: <img className="rounded-lg" src="/securepay.png" />,
    },
    {
      icon: <ServerIcon className="w-9 text-inherit h-9" />,
      title: "Profesyonel Terapi Takip Platformu",
      text: "Kolay kullanım, iyi hizmet.",
      content: <img className="rounded-lg" src="/prof.png" />,
    },
  ];

  useEffect(() => {
    if (tabRefs.current[selectedIndex] && listRef.current) {
      const selectedTab = tabRefs.current[selectedIndex];
      const listTop = listRef.current.getBoundingClientRect().top;
      const tabTop = selectedTab.getBoundingClientRect().top;

      setIndicatorStyles({
        height: selectedTab.offsetHeight,
        top: tabTop - listTop, // Get relative position within Tab.List
      });
    }
  }, [selectedIndex]);

  const handleChangeTab = (index) => {
    setSelectedIndex(index);
  };

  return (
    <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
      <div className="relative w-full mx-auto flex">
        {/* Tab List (Vertical) */}
        <Tab.List
          ref={listRef}
          className="relative flex flex-col space-y-2 p-2 rounded-lg w-1/3"
        >
          {tabs.map((tab, index) => (
            <Tab key={index} as="div" className="relative w-full">
              {({ selected }) => (
                <div
                  ref={(el) => (tabRefs.current[index] = el)}
                  onMouseEnter={() => handleChangeTab(index)}
                  className="w-full relative z-50 text-center cursor-pointer group hover:text-teal-500 opacity-100 py-5 transition-all mb-5 rounded-lg grid grid-cols-6"
                >
                  <div
                    className={`col-span-1 flex items-center justify-center ${
                      Number(selectedIndex) === index
                        ? "text-teal-600 font-semibold"
                        : "text-white"
                    }`}
                  >
                    {tab.icon}
                  </div>
                  <div className="col-span-4 flex flex-col text-left">
                    <p
                      className={`text-lg group-hover:text-teal-600  ${
                        Number(selectedIndex) === index
                          ? "text-teal-600 font-semibold"
                          : "text-white"
                      }`}
                    >
                      {tab.title}
                    </p>
                    <p
                      className={`text-sm group-hover:text-teal-600 ${
                        Number(selectedIndex) === index
                          ? "text-teal-600"
                          : "text-white"
                      }`}
                    >
                      {tab.text}
                    </p>
                  </div>
                </div>
              )}
            </Tab>
          ))}
          {/* Animated Box - Adjusts to Tab Height Dynamically */}
          <Transition
            show
            enter="transition-all duration-300 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            className="absolute left-0 w-full bg-white rounded-lg transition-all duration-300 flex items-center justify-center text-white font-semibold"
            style={{
              height: `${indicatorStyles.height}px`,
              transform: `translateY(${indicatorStyles.top - 16}px)`,
            }}
          />
        </Tab.List>

        {/* Tab Panels */}
        <Tab.Panels className="ml-4 rounded-lg bg-white shadow-md min-h-[150px] flex-1 flex justify-center items-center">
          {tabs.map((tab, index) => (
            <Tab.Panel key={index}>
              <Transition
                appear
                show
                enter="transition-opacity duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
              >
                {tab.content}
              </Transition>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </div>
    </Tab.Group>
  );
}

export default MyTabs;
