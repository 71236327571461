import {
  getFirestore,
  where,
  doc,
  setDoc,
  deleteDoc,
  updateDoc,
  getDoc,
  serverTimestamp,
  orderBy,
  limit,
  startAfter,
  getDocs,
  query,
  addDoc,
  collection,
} from "firebase/firestore";

const db = getFirestore();

class BlogManager {
  static async createBlog(blogData) {
    // Add created_at as timestamp
    try {
      blogData.created_at = serverTimestamp();
      const docRef = await addDoc(collection(db, "blogs"), blogData);
      return {
        success: true,
        message: "Blog created successfully.",
        blogId: docRef.id,
      };
    } catch (error) {
      return { success: false, message: error.message };
    }
  }

  static async updateBlog(blogId, updatedData) {
    try {
      const blogRef = doc(db, "blogs", blogId);

      // Add updated_at timestamp
      updatedData.updated_at = serverTimestamp();

      await updateDoc(blogRef, updatedData);

      return {
        success: true,
        message: "Blog updated successfully.",
      };
    } catch (error) {
      return { success: false, message: error.message };
    }
  }

  static async deleteBlog(blogId) {
    try {
      await deleteDoc(doc(db, "blogs", blogId));
      return { success: true, message: "Blog deleted successfully." };
    } catch (error) {
      return { success: false, message: error.message };
    }
  }

  static async updateBlogStatus(blogId, status) {
    try {
      await updateDoc(doc(db, "blogs", blogId), { status });
      return { success: true, message: "Blog status updated successfully." };
    } catch (error) {
      return { success: false, message: error.message };
    }
  }

  static async updateBlogContent(blogId, content) {
    try {
      await updateDoc(doc(db, "blogs", blogId), { content });
      return { success: true, message: "Blog content updated successfully." };
    } catch (error) {
      return { success: false, message: error.message };
    }
  }

  static async updateBlogCover(blogId, coverURL) {
    try {
      await updateDoc(doc(db, "blogs", blogId), { coverURL });
      return { success: true, message: "Blog cover updated successfully." };
    } catch (error) {
      return { success: false, message: error.message };
    }
  }

  static async getBlog(blogId) {
    try {
      const blogRef = doc(db, "blogs", blogId);
      const blogDoc = await getDoc(blogRef);

      if (!blogDoc.exists()) {
        return { success: false, message: "Blog not found." };
      }

      const blogData = blogDoc.data();

      // ✅ Fetch author data if `authorID` exists
      let authorData = null;
      if (blogData.authorID) {
        const authorRef = doc(db, "doctors", blogData.authorID);
        const authorDoc = await getDoc(authorRef);

        if (authorDoc.exists()) {
          authorData = authorDoc.data();
        }
      }

      // ✅ Attach author data to blog
      return { success: true, data: { ...blogData, author: authorData } };
    } catch (error) {
      return { success: false, message: error.message };
    }
  }

  static async fetchAuthorsByIds(authorIDs) {
    const authors = {};

    // Loop through each authorID and fetch the corresponding document
    for (const authorID of authorIDs) {
      const authorRef = doc(db, "doctors", authorID);
      const authorDoc = await getDoc(authorRef);

      if (authorDoc.exists()) {
        authors[authorID] = authorDoc.data();
      }
    }

    return authors;
  }

  static async getBlogs({ pageSize = 10, lastVisible = null }) {
    try {
      let blogQuery = query(
        collection(db, "blogs"),
        where("status", "==", "live"),
        orderBy("created_at", "desc"),
        limit(pageSize)
      );

      if (lastVisible) {
        blogQuery = query(
          collection(db, "blogs"),
          where("status", "==", "live"),
          orderBy("created_at", "desc"),
          startAfter(lastVisible),
          limit(pageSize)
        );
      }

      const querySnapshot = await getDocs(blogQuery);
      const blogs = [];
      let lastDoc = null;

      querySnapshot.forEach((doc) => {
        blogs.push({ id: doc.id, ...doc.data() });
      });

      if (!querySnapshot.empty) {
        lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
      }

      // ✅ Step 1: Extract unique authorIDs
      const authorIDs = [...new Set(blogs.map((blog) => blog.authorID))];

      if (authorIDs.length === 0) {
        return { success: true, data: blogs, lastVisible: lastDoc };
      }

      // ✅ Step 2: Fetch authors by document IDs
      const authors = await this.fetchAuthorsByIds(authorIDs);

      console.log("Authors", authors);

      // ✅ Step 3: Attach author data to each blog
      const blogsWithAuthors = blogs.map((blog) => ({
        ...blog,
        author: authors[blog.authorID] || null, // Attach author data or null if not found
      }));

      return { success: true, data: blogsWithAuthors, lastVisible: lastDoc };
    } catch (error) {
      return { success: false, message: error.message };
    }
  }

  static async getMyBlogs(pageSize, lastVisible, authorID) {
    try {
      if (!authorID) {
        return { success: false, message: "Author ID is required" };
      }

      let blogQuery = query(
        collection(db, "blogs"),
        where("authorID", "==", authorID),
        orderBy("created_at", "desc"), // Ensure created_at is indexed in Firestore
        limit(pageSize)
      );

      if (lastVisible) {
        blogQuery = query(
          collection(db, "blogs"),
          where("authorID", "==", authorID),
          orderBy("created_at", "desc"),
          startAfter(lastVisible),
          limit(pageSize)
        );
      }

      const querySnapshot = await getDocs(blogQuery);

      if (querySnapshot.empty) {
        return { success: true, data: [], lastVisible: null };
      }

      const blogs = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

      return { success: true, data: blogs, lastVisible: lastDoc };
    } catch (error) {
      console.error("Error fetching blogs:", error);
      return { success: false, message: error.message };
    }
  }
}

export default BlogManager;
