import axios from "axios";
import {
  doc,
  getDoc,
  addDoc,
  updateDoc,
  collection,
  deleteDoc,
  query,
  where,
  getDocs,
  setDoc,
} from "firebase/firestore";
import { firestore, auth } from "../../../firebaseConfig";
import { deleteUser } from "firebase/auth";

export async function getAllUsers() {
  const patients = collection(firestore, "patients");
  const querySnapshot = await getDocs(patients);
  if (!querySnapshot.empty) {
    const patientsData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return patientsData;
  } else {
    return false;
  }
}

//TODO : Test
export async function purgeUser(identifiers) {
  const { id, email, doctorId } = identifiers;
  const patientId = id;

  try {
    if (doctorId) {
      const notesRef = collection(firestore, `doctors/${doctorId}/Notes`);
      const notesQuery = query(notesRef, where("patientId", "==", patientId));
      const notesSnap = await getDocs(notesQuery);
      for (const noteDoc of notesSnap.docs) {
        await deleteDoc(
          doc(firestore, `doctors/${doctorId}/Notes/${noteDoc.id}`)
        );
      }

      // Delete Archived Patients
      const archivedRef = collection(
        firestore,
        `doctors/${doctorId}/Archived Patients`
      );
      const archivedSnap = await getDocs(archivedRef);
      for (const archivedDoc of archivedSnap.docs) {
        await deleteDoc(
          doc(
            firestore,
            `doctors/${doctorId}/Archived Patients/${archivedDoc.id}`
          )
        );
      }
    }

    if (patientId) {
      // Delete Patient Record
      await deleteDoc(doc(firestore, `patients/${patientId}`));

      // Delete Transfer Requests
      const transferRef = collection(firestore, "Transfer Requests");
      const transferQuery = query(
        transferRef,
        where("patientId", "==", patientId)
      );
      const transferSnap = await getDocs(transferQuery);
      for (const transferDoc of transferSnap.docs) {
        await deleteDoc(doc(firestore, `Transfer Requests/${transferDoc.id}`));
      }
    }

    // Delete User from Firebase Authentication
    if (patientId) {
      const response = await axios.post(
        "https://crocodil-backend.vercel.app/deleteUser",
        { data: { uid: patientId } }
      );

      if (response.status === 200) {
        return true;
      }
    }
  } catch (error) {
    console.error("Error purging user data:", error);
  }

  return false;
}
