"use client";

import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import NavLink from "../../../../services/multi-lang/components/LangNavLink";
import { useAuth } from "../../../../context/AuthContext";
import Link from "../../../../services/multi-lang/components/linkWithLang";
import { useLanguage } from "../../../../services/multi-lang/context/Translator";
import LangPicker from "../../../../services/multi-lang/components/LanguagePicker";

const Logo = "/logo2.png";

export default function Example() {
  const { translate } = useLanguage();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { currentUser } = useAuth();

  const navigation = [
    { name: translate("Terapistlerimiz"), href: "/#" },
    { name: translate("Features"), href: "#features" },
    { name: translate("Bloglar"), href: "blogs" },
    { name: translate("Fiyatlandırma"), href: "#pricing" },
  ];

  return (
    <header className="bg-teal-500 border-b border-gray-200 shadow-sm fixed top-0 w-full z-20 ">
      <nav
        aria-label="Global"
        className="mx-auto flex max-w-7xl items-center justify-between p-4 lg:px-8"
      >
        <div className="flex">
          <a href="#" className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            <img alt="" src={Logo} className="w-auto h-14 sm:h-10" />
          </a>
        </div>

        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="size-6" />
          </button>
        </div>

        <div className="hidden lg:flex lg:flex-1  justify-center lg:gap-x-12">
          {navigation.map((item) =>
            item.href.startsWith("#") ? (
              <a
                key={item.name}
                href={item.href} // Normal anchor for ID-based links
                className="text-sm/6 font-semibold text-white"
              >
                {item.name}
              </a>
            ) : (
              <NavLink
                key={item.name}
                to={item.href} // React Router navigation
                className="text-sm/6 font-semibold text-white"
              >
                {item.name}
              </NavLink>
            )
          )}
        </div>
        <div className="hidden lg:flex">
          {currentUser?.uid ? (
            <NavLink
              to="/dashboard"
              className="text-sm font-semibold text-white"
            >
              {translate("Dashboard")} <span aria-hidden="true">&rarr;</span>
            </NavLink>
          ) : (
            <div className="flex items-center gap-3 ">
              <NavLink to="/login" className="text-sm font-semibold text-white">
                {translate("Giriş Yap")} <span aria-hidden="true">&rarr;</span>
              </NavLink>
              <NavLink
                to="/register"
                className="px-3 py-2 mx-2 text-sm font-semibold text-white transition-colors duration-300 transform bg-teal-600 rounded-md hover:bg-teal-700"
              >
                {translate("Terapist Olarak Kayıt Ol")}
              </NavLink>
            </div>
          )}
          {currentUser?.uid && (
            <>
              <Link
                to="/logout"
                className="text-sm font-semibold text-white ml-5"
              >
                Logout
              </Link>
            </>
          )}
        </div>
        <LangPicker />
      </nav>
      <Dialog
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
        className="lg:hidden"
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                alt=""
                src="https://tailwindui.com/plus-assets/img/logos/mark.svg?color=indigo&shade=600"
                className="h-8 w-auto"
              />
            </a>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="size-6" />
            </button>
          </div>
          <div className="mt-12 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) =>
                  item.href.startsWith("#") ? (
                    <a
                      key={item.name}
                      href={item.href} // Use normal anchor for IDs
                      onClick={() => setMobileMenuOpen(false)} // Close menu on click
                      className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ) : (
                    <NavLink
                      key={item.name}
                      to={item.href} // Use React Router for non-ID links
                      onClick={() => setMobileMenuOpen(false)} // Close menu on click
                      className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </NavLink>
                  )
                )}
              </div>
              <div className="py-6">
                {currentUser?.uid ? (
                  <NavLink
                    to="/dashboard"
                    className="text-sm font-semibold text-teal-600"
                  >
                    {translate("Dashboard")}{" "}
                    <span aria-hidden="true">&rarr;</span>
                  </NavLink>
                ) : (
                  <div className="flex flex-col items-start gap-3 ">
                    <NavLink
                      to="/login"
                      className="text-sm font-semibold text-teal-600"
                    >
                      {translate("Giriş Yap")}{" "}
                      <span aria-hidden="true">&rarr;</span>
                    </NavLink>
                    <NavLink
                      to="/register"
                      className="px-3 py-2 text-sm font-semibold text-white transition-colors duration-300 transform bg-teal-600 rounded-md hover:bg-teal-700"
                    >
                      {translate("Terapist Olarak Kayıt Ol")}
                    </NavLink>
                  </div>
                )}
                {currentUser?.uid && (
                  <>
                    <Link
                      to="/logout"
                      className="text-sm font-semibold text-white ml-5"
                    >
                      Logout
                    </Link>
                  </>
                )}
                <LangPicker />
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
