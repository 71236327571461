import { firestore } from "../../../firebaseConfig";
import {
  doc,
  getDoc,
  deleteDoc,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";

const translationsKeys = [
  "Anasayfa",
  "Giriş Yap",
  "Kayıt Ol",
  "Çıkış Yap",
  "Dil Ekle",
  "Dil Seçimi",
  "Dil Adı",
  "Çeviriler & Diller",
];

const emptyParagraphValues = {
  FAQ_1: {
    OriginalQuestion: "Konuşma Sesi Bozuklukları Nedir",
    OriginalAnswer:
      "Çocuğunuz konuşmayı öğrenme aşamasında sözcükleri söylerken bazı hatalar yapabilir. Bu hataların bir kısmı konuşma gelişiminin bir parçasıdır ve belirli yaşlarda çocukların bu hataları yapmaları beklenir. Ancak zamanla bu hataların ortadan kaybolması ve çocuğunuzun sizin gibi anlaşılır bir konuşmaya geçmesi gerekir. Eğer çocuğunuz 4 yaş ve üzerindeyse ve hala bazı sesleri üretmede güçlükler yaşıyorsa, o zaman konuşma sesi bozukluğundan şüphelenebilirsiniz. Konuşma Sesi Bozuklukları (KSB), konuşma seslerini üretmede, algılamada ve/veya dili kurallarına uygun bir şekilde kullanmada yaşanan güçlükler sonucu konuşmanın anlaşılırlığının etkilenmesidir.",
    FAQ_1_Title: "",
    FAQ_1_Paragraph: "",
  },
  FAQ_2: {
    OriginalQuestion: "Gecikmiş Dil ve Konuşma",
    OriginalAnswer:
      "Çocuğunuz konuşmayı öğrenme aşamasında sözcükleri söylerken bazı hatalar yapabilir. Bu hataların bir kısmı konuşma gelişiminin bir parçasıdır ve belirli yaşlarda çocukların bu hataları yapmaları beklenir. Ancak zamanla bu hataların ortadan kaybolması ve çocuğunuzun sizin gibi anlaşılır bir konuşmaya geçmesi gerekir. Eğer çocuğunuz 4 yaş ve üzerindeyse ve hala bazı sesleri üretmede güçlükler yaşıyorsa, o zaman konuşma sesi bozukluğundan şüphelenebilirsiniz. Konuşma Sesi Bozuklukları (KSB), konuşma seslerini üretmede, algılamada ve/veya dili kurallarına uygun bir şekilde kullanmada yaşanan güçlükler sonucu konuşmanın anlaşılırlığının etkilenmesidir.",
    FAQ_2_Title: "",
    FAQ_2_Paragraph: "",
  },
  FAQ_3: {
    OriginalQuestion: "Afazi Nedir",
    OriginalAnswer:
      "Çocuğunuz konuşmayı öğrenme aşamasında sözcükleri söylerken bazı hatalar yapabilir. Bu hataların bir kısmı konuşma gelişiminin bir parçasıdır ve belirli yaşlarda çocukların bu hataları yapmaları beklenir. Ancak zamanla bu hataların ortadan kaybolması ve çocuğunuzun sizin gibi anlaşılır bir konuşmaya geçmesi gerekir. Eğer çocuğunuz 4 yaş ve üzerindeyse ve hala bazı sesleri üretmede güçlükler yaşıyorsa, o zaman konuşma sesi bozukluğundan şüphelenebilirsiniz. Konuşma Sesi Bozuklukları (KSB), konuşma seslerini üretmede, algılamada ve/veya dili kurallarına uygun bir şekilde kullanmada yaşanan güçlükler sonucu konuşmanın anlaşılırlığının etkilenmesidir.",
    FAQ_3_Title: "",
    FAQ_3_Paragraph: "",
  },
  FAQ_4: {
    OriginalQuestion: "Çocukluk Çağı Konuşma Apraksisi",
    OriginalAnswer:
      "Karşımızdakine bir şey söylemek istediğimizde beynimiz, o sözcüğü oluşturan sesleri sıralı ve doğru şekilde üretebilmek için planlama yapıp, gerekli kasları uyarmaktadır. Sıralı hareketler, sözcüğü doğru üretebilmek için konuşmaya yardımcı olan organların doğru zamanda doğru üretim yerlerini seçerek çalışması gibi düşünülebilir. Örneğin ‘ot’ sözcüğünü sesletebilmek için, önce dudaklar yuvarlak pozisyon alarak /o/ sesini, daha sonra dilin ucunu dişlerin arkasıyla temas ettirerek /t/ sesini sıralı şekilde üretmesi gerekir ve bu olay çok kısa bir zamanda gerçekleşir. Dolayısıyla, kas yapısı zarar görmemesine rağmen konuşma üretimi için gereken sıralı hareketlerin motor planlaması ve programlamasındaki bir bozukluktan kaynaklanan bu durum Çocukluk Çağı Konuşma Apraksisi (ÇÇKA) olarak adlandırılır. ÇÇKA olan çocuklar sesleri, heceleri ve sözcükleri istemli üretmede sorun yaşarlar. Bu çocuklar aslında ne söylemek istediğini bilmekte fakat beyin bu sözcükleri söyleyebilmek için gerekli kas hareketlerini yönetmekte zorlandığı için çocuklarda konuşma ile ilgili bir bozukluk ortaya çıkmaktadır.",
    FAQ_4_Title: "",
    FAQ_4_Paragraph: "",
  },
  FAQ_5: {
    OriginalQuestion: "Ses Bozukluğu Nedir",
    OriginalAnswer:
      "Yaşa uygun zekâ seviyesi, eğitim ve çevresel koşullara rağmen, okuma, yazma ve aritmetik gibi akademik becerileri öğrenme ve kullanmada meydana gelen beklenmedik ve hayat boyu devam eden nörogelişimsel bozuklukları kapsayan şemsiye bir terimdir.",
    FAQ_5_Title: "",
    FAQ_5_Paragraph: "",
  },
  FAQ_6: {
    OriginalQuestion: "Gelişimsel Dil Bozukluğu Nedir",
    OriginalAnswer:
      "Çocuğunuz konuşmayı öğrenme aşamasında sözcükleri söylerken bazı hatalar yapabilir. Bu hataların bir kısmı konuşma gelişiminin bir parçasıdır ve belirli yaşlarda çocukların bu hataları yapmaları beklenir. Ancak zamanla bu hataların ortadan kaybolması ve çocuğunuzun sizin gibi anlaşılır bir konuşmaya geçmesi gerekir. Eğer çocuğunuz 4 yaş ve üzerindeyse ve hala bazı sesleri üretmede güçlükler yaşıyorsa, o zaman konuşma sesi bozukluğundan şüphelenebilirsiniz. Konuşma Sesi Bozuklukları (KSB), konuşma seslerini üretmede, algılamada ve/veya dili kurallarına uygun bir şekilde kullanmada yaşanan güçlükler sonucu konuşmanın anlaşılırlığının etkilenmesidir.",
    FAQ_6_Title: "",
    FAQ_6_Paragraph: "",
  },
  FAQ_7: {
    OriginalQuestion: "Akıcılık Bozuklukları",
    OriginalAnswer:
      "Çocuğunuz konuşmayı öğrenme aşamasında sözcükleri söylerken bazı hatalar yapabilir. Bu hataların bir kısmı konuşma gelişiminin bir parçasıdır ve belirli yaşlarda çocukların bu hataları yapmaları beklenir. Ancak zamanla bu hataların ortadan kaybolması ve çocuğunuzun sizin gibi anlaşılır bir konuşmaya geçmesi gerekir. Eğer çocuğunuz 4 yaş ve üzerindeyse ve hala bazı sesleri üretmede güçlükler yaşıyorsa, o zaman konuşma sesi bozukluğundan şüphelenebilirsiniz. Konuşma Sesi Bozuklukları (KSB), konuşma seslerini üretmede, algılamada ve/veya dili kurallarına uygun bir şekilde kullanmada yaşanan güçlükler sonucu konuşmanın anlaşılırlığının etkilenmesidir.",
    FAQ_7_Title: "",
    FAQ_7_Paragraph: "",
  },
  FAQ_8: {
    OriginalQuestion: "Otizm Spektrum Bozukluğu",
    OriginalAnswer:
      "OSB, erken çocukluk döneminde ortaya çıkan, kişinin sözel ya da sözel olmayan iletişimini, sosyal ilişkilerini ve regülasyonunu olumsuz etkileyen ve sınırlı ilgi ile tekrarlı davranışları içeren karmaşık bir nörogelişimsel farklılıktır. Otizm kişileri farklı şekillerde ve değişen boyutlarda etkileyen bir durumdur. O sebeple “spektrum bozukluğu” olarak adlandırılır. Bazı otizmli bireyler öğrenme, düşünme ve problem çözme konusunda ciddi zorluklar yaşarken bazıları bu konularda yüksek başarı sergileyebilir. Benzer şekilde bazı otizmli bireyler günlük yaşam becerilerini sürdürebilmek için desteğe ihtiyaç duyarken bazıları tamamen bağımsız olarak yaşamaktadır.",
    FAQ_8_Title: "",
    FAQ_8_Paragraph: "",
  },
  FAQ_9: {
    OriginalQuestion: "Özgül Öğrenme Güçlüğü Nedir",
    OriginalAnswer:
      "OSB, erken çocukluk döneminde ortaya çıkan, kişinin sözel ya da sözel olmayan iletişimini, sosyal ilişkilerini ve regülasyonunu olumsuz etkileyen ve sınırlı ilgi ile tekrarlı davranışları içeren karmaşık bir nörogelişimsel farklılıktır. Otizm kişileri farklı şekillerde ve değişen boyutlarda etkileyen bir durumdur. O sebeple “spektrum bozukluğu” olarak adlandırılır. Bazı otizmli bireyler öğrenme, düşünme ve problem çözme konusunda ciddi zorluklar yaşarken bazıları bu konularda yüksek başarı sergileyebilir. Benzer şekilde bazı otizmli bireyler günlük yaşam becerilerini sürdürebilmek için desteğe ihtiyaç duyarken bazıları tamamen bağımsız olarak yaşamaktadır.",
    FAQ_9_Title: "",
    FAQ_9_Paragraph: "",
  },
  FAQ_10: {
    OriginalQuestion: "Yutma Bozukluğu Nedir",
    OriginalAnswer:
      "Yutma bozukluğu her yaş grubunda çeşitli hastalıklara bağlı olarak ortaya çıkabilir: Sinir sistemi hasarları: inme, kafa travması, beyin hasarı, omurilik hasarı, Parkinson, Multipl Skleroz, Serebral Palsi, Amiyotrofik Lateral Skleroz, müsküler distrofi, kas hastalıkları Alzheimer, vb, Baş boyun bölgesi ile ilgili hasarlar: ağız temizliğinde sorunlar; ağız içi, dil, yutak, ya da gırtlak kanserleri ve cerrahisi ile radyasyon etkisi vb. Reflü, yemek borusu darlığı gibi gastroözefageal sorunlar ve özellikle çocuklardaki emme-yutma-solunum koordinasyon problemleri, psikolojik nedenler yanı sıra birçok hastalık da yutma bozukluğu ile ilişkili olabilir.",
    FAQ_10_Title: "",
    FAQ_10_Paragraph: "",
  },
  FAQ_11: {
    OriginalQuestion: "Crocodil Nedir",
    OriginalAnswer: `Crocodil, dil ve konuşma terapistleri ile ailelerin terapi sürecini optimize etmek amacıyla özel olarak tasarlanmış bir web platformudur. Hızla gelişen sağlık hizmetleri dünyasında, konuşma terapisi merkezlerinin başarısı ve büyümesi için etkin hasta yönetimi çok önemlidir. Hasta iletişimini kolaylaştıran, otomasyonu geliştiren, terapi adımlarını daha görünür ve kontrol edilebilir kılan bu sistem geleneksel terapi takip ve kayıt anlayışlarınızı değiştirerek terapi oturumlarınızın kalitesini artırmak için size yardımcı olacak.`,
    FAQ_11_Title: "",
    FAQ_11_Paragraph: "",
  },
  FAQ_12: {
    OriginalQuestion: "Nasıl Danışan Eklerim",
    OriginalAnswer: `Sistemimiz içerisinden danışanlarınızın maillerini ekleyerek danışanlarınızın sisteme kaydını yapmış olursunuz. Danışanınızın size verdiği maile gelen şifre ile danışan sistemine girebilir ve sürece dahil olmuş olur. Dilerse şifresini daha sonra değiştirebilir.`,
    FAQ_12_Title: "",
    FAQ_12_Paragraph: "",
  },
  FAQ_13: {
    OriginalQuestion: "Ödemeyi kim yapar",
    OriginalAnswer:
      "Ödemeler terapist tarafından yapılır. Danışandan bir ödeme talep edilmez.",
    FAQ_13_Title: "",
    FAQ_13_Paragraph: "",
  },
  FAQ_14: {
    OriginalQuestion: "Danışanlarımı eklerken hangi bilgiler gereklidir",
    OriginalAnswer:
      "Danışan eklerken danışanınızın adı soyadı, maili ve varsa bakım vereninin adı soyadı lazım. Ve tabi bir iletişim numarası. İşte bu kadar.",
    FAQ_14_Title: "",
    FAQ_14_Paragraph: "",
  },
  FAQ_15: {
    OriginalQuestion: "Crocodili neden kullanmalıyım",
    OriginalAnswer:
      "Sağlık hizmeti uygulamalarının karşılaştığı en büyük zorluklar, hasta kayıtlarını tutmak ve ilerlemelerini izlemek ve danışanla etkili iletişimi artırmak gibi zahmetli görevlerdir. Crocodil terapi takip ve kayıt sistemi bu aşamaları sizin için yerin getirir. Danışan/Terapist arasındaki etkili iletişim danışan memnuniyetini ve danışan tutma oranlarını doğrudan etkilediği için önem taşır. Crocodil içerisindeki yenilikleri ve kullanıcı dostu çözümleriyle terapi oturumlarından alınan memnuniyeti artırmak ve etkili iletişimi sağlamak için gerekli olan sistemi ve çözümleri içerir.",
    FAQ_15_Title: "",
    FAQ_15_Paragraph: "",
  },
  FAQ_16: {
    OriginalQuestion: "Ödemeler nasıl alınıyor",
    OriginalAnswer:
      "Aylık abonelik yöntemine göre her ay belirlenen tarihte ödemeler alınır.",
    FAQ_16_Title: "",
    FAQ_16_Paragraph: "",
  },
  FAQ_17: {
    OriginalQuestion: "Kayıtta istenen bilgiler nelerdir",
    OriginalAnswer:
      "Kayıt için istediğimiz bilgiler mailiniz ve terapist olduğunuzu belgeleyecek birkaç sorudur.",
    FAQ_17_Title: "",
    FAQ_17_Paragraph: "",
  },
  Hero_1: {
    Original_Title: "Crocodil terapi kayıt ve takip programı",
    Original_Subtitle: "Terapi masada",
    Original_Title_Bold: "takip dijitalde",
    Original_Paragraph:
      "Sağlık alanında çalışan her meslek dalında olduğu gibi Dil ve Konuşma Terapistlerinin de danışanlarının sağlık verilerini, terapi oturumlarını ve ödevlendirmelerini kayıt altına almasının önemi her geçen gün artmaktadır. Bu verilerin kaydının daha düzenli yapılması, yeri geldiğinde verilere ulaşımın kolay olması, danışan ve ailelerine geri dönüt ve nesnel veri sağlanmasında kolaylık sağlaması noktasından Crocodil terapi kayıt ve takip sistemi büyük bir boşluğu doldurmaktadır.",
    Hero_1_Title: "",
    Hero_1_Subtitle: "",
    Hero_1_Title_Bold: "",
    Hero_1_Paragraph: "",
  },
  About_us: {
    Original_Title: "Hakkımızda",
    Original_Paragraph: `Crocodil, dil ve konuşma terapistleri ile ailelerin terapi
              sürecini optimize etmek amacıyla özel olarak tasarlanmış bir web
              platformudur. İhtiyaçlarınızı en iyi şekilde karşılamak için
              geliştirilen bu sistem, kullanıcı dostu arayüzüyle kolaylıkla
              kullanılabilir. Misyonumuz, dil ve konuşma terapisi alanında
              hizmet veren profesyonellere teknolojik bir çözüm sunarak işlerini
              daha etkin ve verimli bir şekilde yapmalarına yardımcı olmaktır.
              Aynı zamanda ailelere de evdeki terapi sürecini daha organize bir
              şekilde takip edebilmeleri için bir platform sağlamaktayız.
              Crocodil'in sunduğu temel özellikler arasında hasta kaydı, takibi
              ve ilerlemesinin izlenmesi yer almaktadır. Sistemde her hasta için
              ayrıntılı profiller oluşturulabilir ve bu profillerde hastanın
              geçmiş kayıtları, tedavi planları ve ilerlemesi gibi bilgiler
              kolayca erişilebilir haldedir. Ayrıca uygulamamızda yer alan sesli
              not alma özelliği sayesinde terapistlerin oturumlar sırasında not
              tutması da oldukça pratiktir. Crocodil'in güvenlik önlemleri de en
              üst düzeydedir. Tüm hasta bilgileri şifreli bir şekilde
              saklanmakta olup, yalnızca yetkili kişiler tarafından erişilebilir
              durumdadır. Böylece gizlilik ihlali riski minimuma indirgenmiştir.
              Ayrıca Crocodil'i kullanarak hem dijital içeriklerle hem de
              interaktif egzersizlerle hastalarınıza daha çeşitli terapi
              seansları sunabilirsiniz. Öğretici videolar, oyunlar ve testler
              gibi materyaller ile hastaların motivasyonunu arttırabilir ve daha
              etkili sonuçlar elde edebilirsiniz. Bizim için en önemli unsur ise
              sizin memnuniyetinizdir. Müşterilerimize destek sağlamak amacıyla
              Crocodil üzerinde her zaman kullanıcı desteği sunuyoruz.
              Sorularınız veya problemleriniz olduğunda size yardım etmek için
              buradayız. Crocodil olarak dil ve konuşma terapisinin gücünü
              keşfetmek isteyen tüm profesyonelleri bekliyoruz! Bizimle çalışmak
              için kaydolun ve hastalarınıza daha iyi bir gelecek sunmak için
              bugün adım atın!`,

    About_us_Title: "",
    About_us_Paragraph: "",
  },
};

const emptyExerciseValues = {
  EX_1: {
    OriginalTitle: "1.Sınıf Düzeyi Okuma Metni",
    OriginalSubtitle: "Bebek",
    OriginalParagraph: `Nihal sarı saçlı bebeğini çok severdi. Okuldan gelir gelmez gözü bebeğini arardı. Adını buğday koymuştu bebeğinin. Ailesi, bebeğin ismine çok gülmüştü. Ama Nihal koyduğu ismi çok beğeniyordu. Bebeğinin saçlarını tarlalarındaki buğdaylara benzetiyordu.
Hasat zamanıydı. Ağustosun en sıcak günleriydi. Nihal, buğdayları biçen ailesini seyrediyordu. Tarlanın kıyısındaki ağacın altındaydı. Gölgede bebeği ile oynarken uyuyakaldı. Uyandığında buğday yok olmuştu (Ergeneci, 1999)`,
    EX_1_Title: "",
    EX_1_Subtitle: "",
    EX_1_Paragraph: "",
  },
  EX_2: {
    OriginalTitle: "2.Sınıf Düzeyi Okuma Metni",
    OriginalSubtitle: "Öküz Olmak İsteyen Kurbağa",
    OriginalParagraph: `Kurbağanın biri bir gün dere kenarına gelmiş. Canı çok sıkılıyormuş. Derede kendi etrafında oradan oraya zıplıyormuş. Keyifle çevresine bakmaya başlamış. Tam o sırada çayırda otlayan öküzü görmüş. Öküzün büyüklüğüne hayran olmuş. Kendi kendine:
-bende öküz gibi olabilirim. O zaman herkes benim büyüklüğüme hayran olur, demiş.
Bunun üzerine kurbağa ne yapabilirim diye düşünmeye başlamış birden aklına parlak bir fikir gelmiş ve kendini şişirmeye başlamış. Şiştikçe şişmiş, şiştikçe şişmiş. Biraz daha biraz daha derken çat diye çatlayıvermiş. Öküz olmak isterken ölü bir kurbağa oluvermiş (Ergeneci, Yurtkulu, 2002)`,
    EX_2_Title: "",
    EX_2_Subtitle: "",
    EX_2_Paragraph: "",
  },
  EX_3: {
    OriginalTitle: "3.Sınıf Düzeyi Okuma Metni",
    OriginalSubtitle: "HAVA TAHMİNLERİ",
    OriginalParagraph: `Hava durumu ile ilgili bilgilere ulaşmak bizler için oldukça önemlidir. İnternetten, televizyon, gazete ve ya radyodan hava durumlarındaki değişiklikleri öğrenebiliriz. Böylece günlük işlerimizi daha rahat ayarlayabilir, hava koşullarına göre giyinebilir ve günlük planımızı daha uygun bir şekilde hazırlayabiliriz.
Ayrıca birçok meslek dalı için hava durumunu bilmek oldukça yararlıdır. Örneğin; kaptanlar, balıkçılar, pilotlar, şoförler ve tarımla ilgilenenler için hava durumunu bilmek yararlıdır. Bizler de bulutların şekline ve rengine bakarak hava durumunu tahmin edebiliriz (Ekiztepe, 2004).`,
    EX_3_Title: "",
    EX_3_Subtitle: "",
    EX_3_Paragraph: "",
  },
  EX_4: {
    OriginalTitle: "4.Sınıf Düzeyi Okuma Metni",
    OriginalSubtitle: "PİNTİ",
    OriginalParagraph: `Pintinin biri nesi var nesi yoksa altınla değiştirirmiş. Altını da külçe halinde götürüp gömmüş. Ama gönlünü de aklını da birlikte gömmüş. Her gün bir kez gelir, toprağı kazar, malına bakarmış.
İşçinin biri bunu uzaktan görmüş. İşi anlamış. Gelmiş, altın külçesini alıp götürmüş. Ertesi gün pinti toprağı gene kazmış. Bakmış ki altın yok. Dövünüp ağlamaya, saçlarını yolmaya başlamış.
Oradan biri geçiyormuş: -Ne var, ne oldu, diye sormuş. İşin aslını öğrenince: -Ne ağlıyorsun, be adam, demiş. Senin altının ha varmış, ha yokmuş. Git bir taş al, onu göm, altındır de, çık işin içinden. Senin için altınla taşın bir farkı mı var? Anlaşılıyor ki sen altının varken bir yararını görmüyormuşsun, demiş (Kobak, 2011).`,
    EX_4_Title: "",
    EX_4_Subtitle: "",
    EX_4_Paragraph: "",
  },
  EX_5: {
    OriginalTitle: "5.Sınıf Düzeyi Okuma Metni",
    OriginalSubtitle: "Tilki",
    OriginalParagraph: `Ortalık sıcaktan kavruluyordu. Tilkiciğin dili dışarı sarkmış, kendisine serin gölgelikli bir yer arıyordu. Gide gide kendisini bir meyve bahçesinde buldu. Baktı ki yüksekçe bir asmanın üzeri, iri taneli üzüm salkımlarıyla dopdolu. Ağzı sulandı, hemen asmanın dibine koştu. ‘Aman ne hoş kokusu, ne leziz tadı vardır bunların.’ diyerek asmanın dallarına doğru hücum etti.
Ancak boyu yetişemediği için üzümleri koparamadı. Asmaya tırmanmaya çalıştı, beceremedi. Tuttuğu bir dalı çekerek üzümleri kendisine yaklaştırmaya uğraştı, dal elinde kaldı. Bunun üzerine kızdı ve ‘Aman, kim bilir ne kadar da ekşidir limon gibi o üzümler.’ dedi. Sonra da arkasına bakarak çekti gitti.
Olup bitenleri asmanın üzerinden izleyen küçük kuş ise tilkinin ardından şöyle seslendi: ‘İşte böyledir! Uzanamadığın nimete ekşi der gidersin.’ (Gülpınar, 2014).`,
    EX_5_Title: "",
    EX_5_Subtitle: "",
    EX_5_Paragraph: "",
  },
  EX_6: {
    OriginalTitle: "6.Sınıf Düzeyi Okuma Metni",
    OriginalSubtitle: "KÜRESEL ISINMA, KÜRESEL SORUN",
    OriginalParagraph: `Dünyamızın en önemli sorunlarından birisi de ‘Küresel ısınma’dır. Küresel ısınma; Dünya’nın normal seviyesinin üzerinde ısınmasıyla meydana gelen olumsuz atmosfer olaylarına neden olmaktadır. Kısaca bahsedecek olursak, Dünyamızın ısısı karbondioksit oranının varlığına bağlıdır.
Dünya’da kullanılan bazı gazlar ozon tabakasının delinmesine, böylece karbondioksit dengesinin bozulmasına neden olmaktadır. Böylece Dünya; aşırı ısınmaya, iklim değişmelerine, çöllenmelere, kasırgalara ve buzların erimeleriyle deniz seviyelerinin değişmelerine maruz kalarak yaşanmaz bir hale gelecektir. Bu nedenle ozon tabakasına zarar veren gazların kullanılmaması için gerekli önlemleri almalıyız (Buhan, 2009).`,
    EX_6_Title: "",
    EX_6_Subtitle: "",
    EX_6_Paragraph: "",
  },
  EX_7: {
    OriginalTitle: "7.Sınıf Düzeyi Okuma Metni",
    OriginalSubtitle: "KİBRİTÇİ KIZ",
    OriginalParagraph: `Dehşetli soğuk vardı; kar yağıyor, akşam karanlığı bastırıyordu. Yılbaşı gecesiydi. Bu soğukta, bu karanlıkta küçük bir kızcağız yürüyordu soğukta; başı açık, yalın ayaktı. Evden çıkarken terlik giymesine giymişti, ama bunlar bir işe yaramamıştı. Karşı kaldırıma geçiyordu. Karşısına ansızın doludizgin giden iki araba çıkıverince kızcağız, telaştan bu terlikleri de kaybetti. Birini bir türlü bulamadı, ötekini de bir oğlan alıp kaçtı.
Küçük, işte yalın ayak yürüyordu; minik ayakları soğuktan morarmıştı. Eski püskü önlüğünde bir sürü kibrit vardı. Kibritlerin bir demetini de elinde tutuyordu. Gün boyu kimse ondan bir kibrit bile almamıştı. Karnı acıkmış, üşümüş, yürüyordu; zavallıcık yılgındı, ürkmüştü! Uzun, sarı saçlarına lapa lapa kar yağıyordu. Bütün pencerelerde ışıklar parıldıyor, sokaklar ne de nefis kaz kızartması kokuyordu (Deniz, 2013).`,
    EX_7_Title: "",
    EX_7_Subtitle: "",
    EX_7_Paragraph: "",
  },
  EX_8: {
    OriginalTitle: "8.Sınıf Düzeyi Okuma Metni",
    OriginalSubtitle: "Istanbul",
    OriginalParagraph: `İstanbul… Bir köşesinde cicili bicili apartmanları; bir başka köşesinde tenekeden ve kibrit çöpünden evleri; bir başka köşesinde de asil konakları ve yalılarıyla İstanbul. Günde bin kere yüz değiştiren ve en güzelle en çirkin arasında mekik dokuyan İstanbul…
Bu tezat ve zenginlik dünyası, her şeye rağmen tek ve yekpare birlik halinde benim bütün mekân ölçümü, bütün ruhumu billurlaştırıyor. Onun sefaletinde, zavallılığında bile hiçbir yerin devşiremeyeceği, sırrı yalnız bana belli bir mana görüyorum. Yemiş kaldırımlarının vıcık vıcık çamurunda bile Paris’in tahta kaldırımlarında bulmadığım mana ve şahsiyeti okuyorum (Canpekel, 2012).`,
    EX_8_Title: "",
    EX_8_Subtitle: "",
    EX_8_Paragraph: "",
  },
  EX_9: {
    OriginalTitle: "9.Sınıf Düzeyi Okuma Metni",
    OriginalSubtitle: "GÜNÜN ADAMI",
    OriginalParagraph: `Her şey yoluna girdi ve biz tekrar, hatta eskisinden daha kuvvetle günün adamı olduk. Babacanca hallerim halkın hoşuna gidiyordu. Acayip mazim, icat kabiliyetim, açık kalbim her gün bir kere daha övülüyordu. Hiçbir topluluk yoktu ki bulunmam istenilmesin! Doğrusunu isterseniz ben de şöhretin tam tadını çıkarmaktan hiç çekinmiyordum.
Gözlüğüm, şemsiyem, hiçbir zaman yerine tam oturmayan şapkam, biraz bol kesilmiş elbiselerim, babayani hallerime kadar her şeyim bu muvaffakiyeti besleyecek şekilde tanzim edilmişti. Gittiğim her yerde etrafım çevriliyor. Her meselede fikrim soruluyordu. Umuma ait ölçüleri hiç rahatsız etmeyecek şekilde yaşadığım için seviliyordum (Bektaş, 2013).`,
    EX_9_Title: "",
    EX_9_Subtitle: "",
    EX_9_Paragraph: "",
  },
};

const emptyPricingValues = {};

const emptyValues = {
  Anasayfa: "",
  "Giriş Yap": "",
  "Kayıt Ol": "",
  "Çıkış Yap": "",
  "Dil Ekle": "",
  "Dil Seçimi": "",
  "Dil Adı": "",
  "Çeviriler & Diller": "",
  "KVKK VE AYDINLATMA METNİ": "",
  "TERMS & CONDITIONS": "",
  "DESTEK HATTI": "",
  "Form Ekle": "",
  "Not Ekle": "",
  "Seans Ekle": "",
  "Ödev Ekle": "",
  "Danışan Detayı": "",
  "Terapi Takip Sistemi": "",
  Ödevlendirme: "",
  "Geçmiş Terapiler": "",
  Notlar: "",
  "Danışanın Notları": "",
  Merhaba: "",
  "Şifre Güncelle": "",
  "Aboneliği Yönet": "",
  "Danışan Ekle": "",
  "Mevcut Danışmanı Transfer Et": "",
  "Mevcut Transfer Taleplerim": "",
  "Profil Ayarları": "",
  "Mevcut Danışanlarım": "",
  "Danışan Adı ": "",
  Telefon: "",
  "Bakım Veren Adı Soyadı": "",
  Hece: "",
  Home: "",
  Danışanlarım: "",
  "Danışan Arşivim": "",
  Dosyalarım: "",
  Bloglarım: "",
  "Veri Temizleme": "",
  "Klasör Oluştur": "",
  "Dosya Yükle": "",
  "Metinler & Hesap Makinesi": "",
  Dosyalar: "",
  Araçlar: "",
  Aylık: "",
  "Abone Ol": "",
  "Aylık Abonelik": "",
  "Pakete Dahil Olanlar": "",
  "Sıkça Sorulan Sorular": "",
  "Yayın Tarihi": "",
  Görüntüle: "",
  Düzenle: "",
  Yayınla: "",
  "Yayından Kaldır": "",
  Sil: "",
  "Blog Oluştur": "",
  "Bloglarını Yönet": "",
  "Blogu Kaydet": "",
  "Taslak Olarak Kaydet": "",
  "Blog İsmi": "",
  "Blog Açıklaması": "",
  "Kapak Fotoğrafı Yükle": "",
  "İhtiyacın Olan Her Şey": "",
};

const emptyFeatures = [
  {
    title: "Danışan Takip Sistemi",
    text: "Danışanlarınızın geçmiş ve güncel seanslarını kaydedin, ilerleme süreçlerini kolayca takip edin.",
    feature_title: "",
    feature_text: "",
  },
  {
    title: "Ödevlendirme Modülü",
    text: "Danışanlarınıza terapi sonrası ödevler atayın ve gelişim süreçlerini kontrol edin.",
    feature_title: "",
    feature_text: "",
  },
  {
    title: "Terapistler Arası İş Birliği",
    text: "Danışan devretme özelliği ile meslektaşlarınızla bilgi paylaşımını kolaylaştırın ve terapi süreçlerini kesintisiz sürdürebilirsiniz.",
    feature_title: "",
    feature_text: "",
  },
  {
    title: "Dijital Materyal Arşivi",
    text: "Özel terapi materyallerinizi düzenli bir şekilde saklayın, ihtiyacınıza göre hızlıca erişin.",
    feature_title: "",
    feature_text: "",
  },
  {
    title: "Güvenli ve Kolay Kullanım",
    text: "Crocodil, kullanıcı dostu arayüzü ve güçlü güvenlik altyapısı ile verilerinizi güvenli bir şekilde saklar.",
    feature_title: "",
    feature_text: "",
  },
  {
    title: "Crocodil ile Dijital Çağa Ayak Uydurun!",
    text: "Terapilerinizi organize etmek, danışan süreçlerini iyileştirmek ve meslektaşlarınızla daha güçlü bir iş birliği sağlamak için Crocodil’i hemen keşfedin!",
    feature_title: "",
    feature_text: "",
  },
];

class LanguageService {
  constructor() {
    this.languages = this.getLanguageListFromFirebase();
  }

  async getLanguageListFromFirebase() {
    const languagesQuery = query(
      collection(firestore, "languages"),
      where("isUsable", "==", true)
    );

    const languages = await getDocs(languagesQuery);

    const languageList = languages.docs.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });

    return languageList;
  }

  async getLanguages() {
    return this.languages;
  }

  disableLanguage(languageId) {
    try {
      const languageRef = doc(firestore, "languages", languageId);

      updateDoc(languageRef, {
        isUsable: false,
      });

      const newLanguages = this.getLanguageListFromFirebase();

      this.languages = newLanguages;
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  createLanguage(language) {
    try {
      const languageRef = collection(firestore, "languages");

      addDoc(languageRef, language);

      const newLanguages = this.getLanguageListFromFirebase();

      this.languages = newLanguages;

      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  updateLanguage(languageId, language) {
    try {
      const languageRef = doc(firestore, "languages", languageId);

      setDoc(languageRef, language);
    } catch (e) {
      console.log(e);
    }
  }

  async deleteLanguage(languageId) {
    try {
      const languageRef = doc(firestore, "languages", languageId);

      const newLanguages = this.getLanguageListFromFirebase();

      this.languages = newLanguages;

      await deleteDoc(languageRef);
    } catch (e) {
      console.log(e);
    }
  }

  async getLanguageById(languageId) {
    try {
      const languageRef = doc(firestore, "languages", languageId);

      const language = await getDoc(languageRef);

      return language.data();
    } catch (e) {
      console.log(e);
    }
  }

  async getLanguageByName(languageName) {
    try {
      const languagesQuery = query(
        collection(firestore, "languages"),
        where("name", "<=", languageName)
      );

      const languages = await getDocs(languagesQuery);

      const language = languages.docs[0];

      return language.data();
    } catch (e) {
      console.log(e);
    }
  }

  async getDictionary(ISOCODE) {
    try {
      const dictionaryRef = doc(firestore, "translations", ISOCODE);

      const dictionary = await getDoc(dictionaryRef);

      const dictionaryData = dictionary.data();

      //If dictionary is empty,undefined or null return false else return dictionary

      if (
        !dictionaryData ||
        Object.keys(dictionaryData).length === 0 ||
        dictionaryData.constructor !== Object
      ) {
        return false;
      }

      return dictionaryData;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  //TODO : Error Handling
  async updateDictionaryValue(ISOCODE, key, value) {
    try {
      const dictionaryRef = doc(firestore, "translations", ISOCODE);
      const dictionary = await getDoc(dictionaryRef);

      if (dictionary.exists()) {
        await updateDoc(dictionaryRef, { [key]: value });
      } else {
        await setDoc(dictionaryRef, { [key]: value });
      }

      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async createDictionary(ISOCODE, dictionary) {
    try {
      const dictionaryRef = doc(collection(firestore, "translations"));

      addDoc(dictionaryRef, dictionary);
    } catch (e) {
      console.log(e);
    }
  }

  async deleteDictionary(ISOCODE) {
    try {
      const dictionaryRef = doc(firestore, "translations", ISOCODE);

      await deleteDoc(dictionaryRef);
    } catch (e) {
      console.log(e);
    }
  }
}

const languageService = new LanguageService();

export {
  languageService,
  translationsKeys,
  emptyValues,
  emptyParagraphValues,
  emptyExerciseValues,
};
