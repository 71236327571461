import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/doctor/sidebar/Index";
import BlogManager from "../../services/cms/functions";
import moment from "moment";
import { useAuth } from "../../context/AuthContext";
import Swal from "sweetalert2";
import NavLink from "../../services/multi-lang/components/LangNavLink";
import LangNavLink from "../../services/multi-lang/components/LangNavLink";
import { useLanguage } from "../../services/multi-lang/context/Translator";

export default function Archive() {
  const [blogs, setBlogs] = useState([]);
  const { translate } = useLanguage();
  const { currentUser } = useAuth();
  const { currentLanguage } = useLanguage();

  const getBlogs = async (pageSize = 10, lastElement = null) => {
    const uid = currentUser.uid;
    const blogs = await BlogManager.getMyBlogs(pageSize, null, uid);
    if (blogs.success) {
      setBlogs(blogs.data);
    } else {
      setBlogs([]);
    }
  };

  const blogStatus = {
    pending: {
      btnText: translate("Yayınla"),
      status: "live",
    },
    live: {
      btnText: translate("Yayından Kaldır"),
      status: "pending",
    },
  };

  const handleDelete = (blogId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        BlogManager.deleteBlog(blogId).then(() => {
          Swal.fire("Deleted!", "Your blog has been deleted.", "success");
          getBlogs();
        });
      }
    });
  };

  const handleChangeStatus = (blogId, status) => {
    Swal.fire({
      title: "Change Status?",
      text: `Do you want to set this blog to ${status}?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    }).then((result) => {
      if (result.isConfirmed) {
        BlogManager.updateBlogStatus(blogId, status).then(() => {
          Swal.fire("Updated!", "The blog status has been updated.", "success");
          getBlogs();
        });
      }
    });
  };

  useEffect(() => {
    getBlogs(10);
  }, []);

  return (
    <Layout>
      <div className="flex items-end gap-3 justify-between w-full p-5">
        <h2 className="text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
          Manage your Blogs
        </h2>
        <LangNavLink
          to="/blog-manager/create"
          className="text-white font-semibold text-sm p-2  rounded-md bg-teal-600"
        >
          {translate("Blog Oluştur")} +
        </LangNavLink>
      </div>
      <div className="grid grid-cols-2 gap-2 p-5">
        {blogs.map((blog) => (
          <article
            key={blog.id}
            className="relative isolate flex flex-col gap-8 lg:flex-row "
          >
            <div className="relative aspect-video sm:aspect-[2/1] lg:aspect-square lg:w-64 lg:shrink-0">
              <img
                alt=""
                src={blog.coverURL}
                className="absolute inset-0 size-full rounded-2xl bg-gray-50 object-cover"
              />
              <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
            </div>
            <div>
              <div className="flex items-center gap-x-4 text-xs">
                <time
                  dateTime={blog.created_at.toDate()}
                  className="text-gray-500"
                >
                  {translate("Yayın Tarihi")} :{" "}
                  {moment(blog.created_at.toDate()).format("DD/MM/yyyy")}
                </time>
              </div>
              <div className="group relative max-w-xl">
                <h3 className="mt-3 text-lg/6 font-semibold text-gray-900 group-hover:text-gray-600">
                  <a
                    target="_blank"
                    href={"/" + currentLanguage + "/blog/" + blog.id}
                  >
                    <span className="absolute inset-0" />
                    {blog.title}
                  </a>
                </h3>
                <p className="mt-5 text-sm/6 text-gray-600">
                  {blog.description}
                </p>
              </div>
              <div className="mt-6 flex border-t border-gray-900/5 pt-2 items-center gap-3">
                <a
                  target="_blank"
                  href={"/" + currentLanguage + "/blog/" + blog.id}
                  className="px-4 py-2 text-sm font-medium text-white bg-teal-500 rounded-lg hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
                >
                  {translate("Görüntüle")}
                </a>
                <NavLink
                  to={"/blog-manager/edit/" + blog.id}
                  className="px-4 py-2 text-sm font-medium text-white bg-teal-500 rounded-lg hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
                >
                  {translate("Düzenle")}
                </NavLink>
                {blog.status === "draft" ? (
                  <button
                    onClick={() => handleChangeStatus(blog.id, "pending")}
                    className="px-4 py-2 text-sm font-medium text-white bg-gray-500 rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300"
                  >
                    {translate("Taslak Olarak Kaydet")}
                  </button>
                ) : (
                  <button
                    onClick={() =>
                      handleChangeStatus(
                        blog.id,
                        blogStatus[blog.status].status
                      )
                    }
                    className="px-4 py-2 text-sm font-medium text-white bg-teal-500 rounded-lg hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-green-300"
                  >
                    {blogStatus[blog.status].btnText}
                  </button>
                )}
                <button
                  onClick={() => handleDelete(blog.id)}
                  className="px-4 py-2 text-sm font-medium text-white bg-red-400 rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-300"
                >
                  {translate("Sil")}
                </button>
              </div>
            </div>
          </article>
        ))}
      </div>
    </Layout>
  );
}
