import React, { useEffect, useState } from "react";

export default function Content({ data }) {
  const [content, setContent] = useState("");

  useEffect(() => {
    if (data?.length > 0) {
      console.log(data);
      setContent(serializeContent(data));
    }
  }, [data]);

  const serializeContent = (nodes) => {
    if (!nodes || !Array.isArray(nodes)) return "";

    return nodes
      .map((node) => {
        if (node.text !== undefined) {
          let text = node.text.trim();
          if (text === "") return `<br/>`; // Ensure empty text nodes are replaced with <br/>
          if (node.bold) text = `<strong class="font-bold">${text}</strong>`;
          if (node.italic) text = `<em class="italic">${text}</em>`;
          if (node.underline) text = `<u class="underline">${text}</u>`;
          if (node.strikethrough) text = `<s class="line-through">${text}</s>`;
          return text;
        }

        switch (node.type) {
          case "h2":
            return `<p class="mt-2 text-pretty text-2xl font-semibold tracking-tight text-gray-700 sm:text-3xl text-${
              node.align || "left"
            }">${serializeContent(node.children)}</p>`;
          case "h3":
            return `<p class="mt-2 text-pretty text-xl font-semibold tracking-tight text-gray-600 sm:text-2xl text-${
              node.align || "left"
            }">${serializeContent(node.children)}</p>`;
          case "blockquote":
            return `<blockquote class="border-l-4 border-gray-500 pl-4 italic">${serializeContent(
              node.children
            )}</blockquote>`;
          case "code":
            return `<pre class="bg-gray-900 text-white p-4 rounded-md"><code>${serializeContent(
              node.children
            )}</code></pre>`;
          case "bulleted-list":
            return `<ul class="list-disc pl-5">${serializeContent(
              node.children
            )}</ul>`;
          case "numbered-list":
            return `<ol class="list-decimal pl-5">${serializeContent(
              node.children
            )}</ol>`;
          case "list-item":
            return `<li class="mb-1">${serializeContent(node.children)}</li>`;
          case "link":
            return `<a href="${
              node.url
            }" target="_blank" rel="noopener noreferrer" class="text-blue-500 underline hover:text-blue-700">${serializeContent(
              node.children
            )}</a>`;
          case "image":
            return `<div style="display: flex; justify-content: ${
              node.align === "center"
                ? "center"
                : node.align === "right"
                ? "flex-end"
                : "flex-start"
            }; padding: 5px;">
              <img src="${node.url}" alt="${
              node.alt || "Image"
            }" class="max-w-full h-auto block my-4" />
            </div>`;
          case "paragraph": {
            const content = serializeContent(node.children).trim();
            return content === ""
              ? "<br/>"
              : `<p class="text-base text-gray-500 text-${
                  node.align || "left"
                }">${content}</p>`;
          }
          default:
            return `<p class="text-base text-gray-500 text-${
              node.align || "left"
            }">${serializeContent(node.children)}</p>`;
        }
      })
      .join("");
  };

  return (
    <div
      id="content-preview"
      className="mt-4 mx-auto"
      dangerouslySetInnerHTML={{ __html: content }}
    ></div>
  );
}
