import { useEffect, useState } from "react";
import Layout from "../../components/layout/landing/Layout";
import BlogManager from "../../services/cms/functions";
import moment from "moment";
import LangNavLink from "../../services/multi-lang/components/LangNavLink";
import { useLanguage } from "../../services/multi-lang/context/Translator";

export default function Example() {
  const { translate } = useLanguage();
  const [blogs, setBlogs] = useState([]);

  const getBlogs = async (pageSize = 10, lastElement = null) => {
    const blogs = await BlogManager.getBlogs(10);
    if (blogs.success) {
      setBlogs(blogs.data);
    } else {
      setBlogs([]);
    }
  };

  useEffect(() => {
    getBlogs();
  }, []);

  return (
    <Layout>
      <div className="bg-white py-24 sm:py-32 w-full">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:max-w-4xl">
            <h2 className="text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
              {translate("Terapistlerimizden")}
            </h2>
            <p className="mt-2 text-lg/8 text-gray-600">
              {translate("Blog_Title_P")}
            </p>
            <div className="mt-16 space-y-20 lg:mt-20 lg:space-y-20">
              {blogs.length === 0 && <p> No Content Yet</p>}
              {blogs.map((blog) => (
                <LangNavLink key={blog.id} to={"/blog/" + blog.id}>
                  <article className="relative isolate flex flex-col gap-8 lg:flex-row mt-10">
                    <div className="relative aspect-video sm:aspect-[2/1] lg:aspect-square lg:w-64 lg:shrink-0">
                      <img
                        alt=""
                        src={blog.coverURL}
                        className="absolute inset-0 size-full rounded-2xl bg-gray-50 object-fit"
                      />
                      <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                    <div>
                      <div className="group relative max-w-xl">
                        <h3 className="mt-1.5 text-lg/6 font-semibold text-gray-900 group-hover:text-gray-600">
                          <a href={blog.id}>
                            <span className="absolute inset-0" />
                            {blog.title}
                          </a>
                        </h3>
                        <div className="flex items-center gap-x-4 text-xs">
                          <div className="relative flex items-center gap-2 my-2">
                            <img
                              alt=""
                              src={blog.author?.photoUrl}
                              className="size-10 object-fit border border-1 border-gray-300 shadow rounded-full bg-gray-50"
                            />
                            <div className="mb-1">
                              <p className="text-sm/6 text-gray-600">
                                {blog.author?.firstName +
                                  " " +
                                  blog.author?.lastName}
                              </p>
                              <p className="text-xs text-gray-500">
                                {translate("Yayın Tarihi")} :{" "}
                                {moment(blog?.created_at?.toDate()).format(
                                  "DD/MM/yyyy"
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                        <p className="mt-2 text-sm/6 text-gray-600">
                          {blog.description}
                        </p>
                      </div>
                    </div>
                  </article>
                </LangNavLink>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
