export class FileItem {
  constructor({
    id = null,
    name = "",
    path = "",
    size = 0,
    type = "",
    createdAt = null,
    updatedAt = null,
    url = "",
    ownerId = null,
    isPublic = false,
  } = {}) {
    this.id = id;
    this.name = name;
    this.path = path; // e.g. "root/childFolder"
    this.size = size; // file size in bytes
    this.type = type; // e.g. "image/png"
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.url = url; // download URL from Firebase Storage
    this.ownerId = ownerId;
    this.isPublic = isPublic;
  }
}
