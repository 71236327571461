import React, { useState } from "react";
import { Disclosure, Transition } from "@headlessui/react";
import {
  CalendarDaysIcon,
  DocumentIcon,
  BuildingLibraryIcon,
  ServerIcon,
} from "@heroicons/react/24/outline";
import { ChevronUpIcon } from "@heroicons/react/24/solid";

function MobileAccordion() {
  const [openIndex, setOpenIndex] = useState(null);

  const tabs = [
    {
      icon: <DocumentIcon className="w-6 h-6 text-teal-600" />,
      title: "Dökümantasyon Araçları",
      text: "Not tutmanızı ve dokümantasyonunuzu kolaylaştırın.",
      content: (
        <img
          className="rounded-lg w-full"
          src="/docum.png"
          alt="Documentation"
        />
      ),
    },
    {
      icon: <BuildingLibraryIcon className="w-6 h-6 text-teal-600" />,
      title: "Güvenli Ödeme & Üyelik",
      text: "Kolay, Hızlı ve Dijital.",
      content: (
        <img
          className="rounded-lg w-full"
          src="/securepay.png"
          alt="Billing & Payments"
        />
      ),
    },
    {
      icon: <ServerIcon className="w-6 h-6 text-teal-600" />,
      title: "Profesyonel Terapi Takip Platformu",
      text: "Kolay kullanım, iyi hizmet.",
      content: (
        <img
          className="rounded-lg w-full"
          src="/prof.png"
          alt="Client Platform"
        />
      ),
    },
  ];

  return (
    <div className="w-full mx-auto p-4">
      {tabs.map((tab, index) => (
        <Disclosure key={index} as="div" className="mb-4">
          {({ open }) => (
            <>
              <Disclosure.Button
                className="flex w-full justify-between min-h-24 items-center px-4 py-3 text-left bg-white rounded-lg shadow-md hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-teal-500 focus-visible:ring-opacity-75"
                onClick={() => setOpenIndex(open ? null : index)}
              >
                <div className="flex items-center space-x-3">
                  {tab.icon}
                  <div>
                    <p className="text-lg font-semibold text-gray-900">
                      {tab.title}
                    </p>
                    <p className="text-sm text-gray-500">{tab.text}</p>
                  </div>
                </div>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180" : ""
                  } w-5 h-5 text-gray-600 transition-transform`}
                />
              </Disclosure.Button>
              <Transition
                show={open}
                enter="transition-opacity duration-300 ease-out"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-200 ease-in"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Disclosure.Panel className="p-1 bg-gray-50 rounded-lg mt-3 shadow-inner">
                  {tab.content}
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      ))}
    </div>
  );
}

export default MobileAccordion;
