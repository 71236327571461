import React, { useState, useRef } from "react";
import { useLangNavigation } from "../../multi-lang/components/navigateWithLang";
import Swal from "sweetalert2";
import Editor from "../components/Editor";
import { TextInput, TextareaInput } from "../../../components/inputs/Index";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEditor } from "../hooks/useEditor";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { storage } from "../../../firebaseConfig"; // Adjust path as per your project structure
import { useAuth } from "../../../context/AuthContext";
import BlogManager from "../functions";

function FileExplorer() {
  const navigate = useLangNavigation();
  const { currentUser } = useAuth();
  const { editorContent, setEditorContent } = useEditor();
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const formik = useFormik({
    initialValues: {
      title: "Yeni Blogunuz",
      description: "",
      coverURL: "",
      status: "pending",
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required("Blog ismi zorunludur.")
        .min(3, "Blog ismi en az 3 karakter olmalıdır.")
        .max(100, "Blog ismi en fazla 100 karakter olabilir."),
    }),
    onSubmit: (values) => {
      values.content = editorContent;
      values.authorID = currentUser.uid;
      BlogManager.createBlog(values).then((response) => {
        Swal.fire({
          title: "Blog Oluşturuldu",
          icon: "success",
        }).then(() => {
          navigate("/blog-manager");
        });
      });
    },
  });

  const setStatusDraft = () => {
    formik.setFieldValue("status", "draft");
  };

  const [previewImage, setPreviewImage] = useState(null);
  const fileInputRef = useRef(null); // Ref for clearing input field

  const uploadFile = async (file, folderPath) => {
    if (!file) throw new Error("No file provided.");

    return new Promise((resolve, reject) => {
      // Rename file to prevent duplicates
      const uniqueFileName = `${Date.now()}_${file.name}`;
      const storageRef = ref(storage, `${folderPath}/${uniqueFileName}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error("Upload failed:", error);
          reject(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        }
      );
    });
  };

  const handleImageUpload = async (file) => {
    if (!file) return;

    try {
      // Show a local preview while uploading
      const reader = new FileReader();
      reader.onload = () => setPreviewImage(reader.result);
      reader.readAsDataURL(file);

      setUploading(true);
      setUploadProgress(0);

      // Upload file and get the download URL
      const downloadURL = await uploadFile(file, "blog_covers");

      // Set formik value and update preview with the final uploaded image
      formik.setFieldValue("coverURL", downloadURL);
      console.log("URL", downloadURL);
      setPreviewImage(downloadURL);
      setUploading(false);

      console.log("Image successfully uploaded:", downloadURL);

      // Reset file input
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } catch (error) {
      console.error("Upload error:", error);
      setUploading(false);
      setPreviewImage(null);
    }
  };

  return (
    <div className="p-4 bg-white m-5 rounded-md border border-1 border-gray-300 shadow-sm pt-5 pb-10">
      <form
        onSubmit={formik.handleSubmit}
        className="w-full grid grid-cols-2 gap-5"
      >
        <div>
          <TextInput
            label="Blog İsmi"
            value={formik.values.title}
            name="title"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.title && formik.errors.title && (
            <p className="text-red-500 text-xs mt-1">{formik.errors.title}</p>
          )}
          <br />
          <TextareaInput
            label="Blog Açıklama"
            value={formik.values.description}
            name="description"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <label className="block text-sm font-medium text-gray-700 mt-4">
            Kapak Fotoğrafı Yükle
          </label>
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            onChange={(e) => handleImageUpload(e.target.files[0])}
            className="mt-2 border p-2 w-full rounded-md"
          />
          {/* Show progress bar during upload */}
          {uploading && (
            <p className="text-blue-500 text-xs mt-2">
              Yükleniyor... %{uploadProgress.toFixed(0)}
            </p>
          )}
          {(previewImage || formik.values.coverURL) && (
            <img
              src={previewImage || formik.values.coverURL}
              alt="Cover Preview"
              className="mt-3 w-48 h-32 object-cover rounded-md border"
            />
          )}
        </div>

        <div className="mt-auto ml-auto flex items-center gap-3">
          <button
            type="submit"
            onClick={setStatusDraft}
            className="bg-gray-500 text-white p-2.5 h-max mt-auto rounded-md w-max ml-auto"
          >
            Save as Draft
          </button>

          <button
            type="submit"
            className="bg-teal-500 text-white p-2.5 h-max mt-auto rounded-md w-max ml-auto"
          >
            Bu Blogu Kaydet
          </button>
        </div>
      </form>

      <Editor />
    </div>
  );
}

export default FileExplorer;
