// storageService.js
import {
  collection,
  doc,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";

import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

import { firestore, auth, storage } from "../../../firebaseConfig.js";

import { FileItem } from "../models/fileItem.js";
import { FolderItem } from "../models/folderItem.js";

export class StorageService {
  constructor() {
    // Initialize Firestore and Storage references
    this.db = firestore; // Firestore instance
    this.storage = storage; // Storage instance
  }

  /* -------------------------- FILE OPERATIONS -------------------------- */

  /**
   * Creates and uploads a file, and stores its metadata in Firestore.
   * @param {File} file - A JavaScript File object
   * @param {string} folderPath - A string representing the parent folder path in Storage
   */
  async createFile(file, folderPath = "", isPublic = false) {
    try {
      // 1. Upload file to Firebase Storage under `folderPath/file.name`
      const storageRef = ref(this.storage, `${folderPath}/${file.name}`);
      await uploadBytes(storageRef, file);

      // 2. Get the download URL
      const downloadURL = await getDownloadURL(storageRef);

      const currentUser = auth.currentUser;
      // 3. Save file metadata to Firestore (in a "files" collection)
      const fileData = {
        name: file.name,
        path: folderPath,
        size: file.size,
        type: file.type,
        createdAt: new Date(),
        updatedAt: new Date(),
        url: downloadURL,
        ownerId: currentUser.uid,
        isPublic,
      };

      const docRef = await addDoc(collection(this.db, "files"), fileData);

      // 4. Return a FileItem instance
      return new FileItem({
        ...fileData,
        id: docRef.id,
      });
    } catch (error) {
      console.error("Error creating file:", error);
      throw error;
    }
  }

  /**
   * Retrieves file metadata from Firestore by file document ID.
   * @param {string} fileId - Document ID of the file in Firestore
   * @returns {FileItem}
   */
  async readFile(fileId) {
    try {
      const docRef = doc(this.db, "files", fileId);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        throw new Error(`File with ID "${fileId}" not found`);
      }

      return new FileItem({
        ...docSnap.data(),
        id: docSnap.id,
      });
    } catch (error) {
      console.error("Error reading file:", error);
      throw error;
    }
  }

  /**
   * Updates file metadata in Firestore.
   * (Does not handle re-uploading files in Storage by default, but you can.)
   * @param {string} fileId - Document ID of the file in Firestore
   * @param {object} updates - Key-value updates (e.g. { name: 'newName' })
   */
  async updateFile(fileId, updates = {}) {
    try {
      const docRef = doc(this.db, "files", fileId);
      // Ensure we update the "updatedAt" field
      await updateDoc(docRef, {
        ...updates,
        updatedAt: new Date(),
      });

      // Return the updated item
      return this.readFile(fileId);
    } catch (error) {
      console.error("Error updating file:", error);
      throw error;
    }
  }

  /**
   * Deletes a file both from Firebase Storage and its metadata from Firestore.
   * @param {string} fileId - Document ID of the file in Firestore
   */
  async deleteFile(fileId) {
    try {
      // 1. Retrieve file metadata
      const fileItem = await this.readFile(fileId);

      // 2. Delete from Storage
      const storageRef = ref(this.storage, `${fileItem.path}/${fileItem.name}`);
      await deleteObject(storageRef);

      // 3. Delete Firestore doc
      const docRef = doc(this.db, "files", fileId);
      await deleteDoc(docRef);

      return true;
    } catch (error) {
      console.error("Error deleting file:", error);
      throw error;
    }
  }

  /* ------------------------- FOLDER OPERATIONS ------------------------- */

  /**
   * Creates a folder document in Firestore.
   * @param {string} folderName - Name of the folder
   * @param {string} parentPath - Path of the parent folder (e.g. 'root/childFolder')
   */
  async createFolder(folderName, parentPath = "", isPublic = false) {
    try {
      const currentUser = auth.currentUser;
      const folderData = {
        name: folderName,
        path: parentPath,
        createdAt: new Date(),
        updatedAt: new Date(),
        isPublic,
        ownerId: currentUser.uid,
      };

      const docRef = await addDoc(collection(this.db, "folders"), folderData);

      return new FolderItem({
        ...folderData,
        id: docRef.id,
      });
    } catch (error) {
      console.error("Error creating folder:", error);
      throw error;
    }
  }

  /**
   * Reads a folder document from Firestore by ID.
   * @param {string} folderId - Document ID of the folder in Firestore
   */
  async readFolder(folderId) {
    try {
      const docRef = doc(this.db, "folders", folderId);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        throw new Error(`Folder with ID "${folderId}" not found`);
      }

      return new FolderItem({
        ...docSnap.data(),
        id: docSnap.id,
      });
    } catch (error) {
      console.error("Error reading folder:", error);
      throw error;
    }
  }

  /**
   * Updates folder metadata in Firestore.
   * @param {string} folderId - Document ID of the folder in Firestore
   * @param {object} updates - Key-value updates (e.g. { name: 'newName' })
   */
  async updateFolder(folderId, updates = {}) {
    try {
      const docRef = doc(this.db, "folders", folderId);
      await updateDoc(docRef, {
        ...updates,
        updatedAt: new Date(),
      });

      return this.readFolder(folderId);
    } catch (error) {
      console.error("Error updating folder:", error);
      throw error;
    }
  }

  /**
   * Deletes a folder document from Firestore.
   *
   * > NOTE: This does not automatically delete files or subfolders inside this folder.
   * You'd need additional logic (e.g., listing all child files/folders) to truly remove everything.
   *
   * @param {string} folderId - Document ID of the folder in Firestore
   */
  async deleteFolder(folderId) {
    try {
      const docRef = doc(this.db, "folders", folderId);
      await deleteDoc(docRef);
      return true;
    } catch (error) {
      console.error("Error deleting folder:", error);
      throw error;
    }
  }

  /* ---------------------- LISTING FILES & FOLDERS ---------------------- */

  /**
   * Lists all files in a specified folderPath (from Firestore).
   * @param {string} folderPath - The folder path to look for in "files" collection
   */
  async listFiles(folderPath) {
    try {
      const user = auth.currentUser;
      if (!user) throw new Error("User is not authenticated");

      const filesCollection = collection(this.db, "files");

      // Query public files
      const publicFilesQuery = query(
        filesCollection,
        where("path", "==", folderPath),
        where("isPublic", "==", true)
      );

      // Query user-owned files
      const ownedFilesQuery = query(
        filesCollection,
        where("path", "==", folderPath),
        where("ownerId", "==", user.uid)
      );

      const [publicFilesSnapshot, ownedFilesSnapshot] = await Promise.all([
        getDocs(publicFilesQuery),
        getDocs(ownedFilesQuery),
      ]);

      const filesMap = new Map();
      publicFilesSnapshot.forEach((docSnap) => {
        filesMap.set(docSnap.id, { ...docSnap.data(), id: docSnap.id });
      });
      ownedFilesSnapshot.forEach((docSnap) => {
        filesMap.set(docSnap.id, { ...docSnap.data(), id: docSnap.id });
      });

      const files = Array.from(filesMap.values());

      console.log("Files", files);

      return files;
    } catch (error) {
      console.error("Error listing files:", error);
      throw error;
    }
  }

  /**
   * Lists all folders with a specified parentPath in the "folders" collection.
   * @param {string} parentPath - The parent folder path
   */
  async listFolders(parentPath) {
    try {
      const user = auth.currentUser;
      if (!user) throw new Error("User is not authenticated");

      const foldersCollection = collection(this.db, "folders");

      // Query public folders
      const publicFoldersQuery = query(
        foldersCollection,
        where("path", "==", parentPath),
        where("isPublic", "==", true)
      );

      // Query user-owned folders
      const ownedFoldersQuery = query(
        foldersCollection,
        where("path", "==", parentPath),
        where("ownerId", "==", user.uid)
      );

      const [publicFoldersSnapshot, ownedFoldersSnapshot] = await Promise.all([
        getDocs(publicFoldersQuery),
        getDocs(ownedFoldersQuery),
      ]);

      const foldersMap = new Map();
      publicFoldersSnapshot.forEach((docSnap) => {
        foldersMap.set(docSnap.id, { ...docSnap.data(), id: docSnap.id });
      });
      ownedFoldersSnapshot.forEach((docSnap) => {
        foldersMap.set(docSnap.id, { ...docSnap.data(), id: docSnap.id });
      });

      const folders = Array.from(foldersMap.values());

      console.log("folders", folders);

      return folders;
    } catch (error) {
      console.error("Error listing folders:", error);
      throw error;
    }
  }
}
