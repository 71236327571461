import React, { useEffect, useState } from "react";
import { formatFileSize } from "../../../functions/index";
import { PaperClipIcon, ArrowDownTrayIcon } from "@heroicons/react/24/outline";

/**
 * FileCard component
 * @param {Object} props
 * @param {Object} props.file  - File data (with name, size, etc.)
 * @param {Function} props.onClick - Handler when file is clicked
 * @param {Function} [props.onDelete] - Handler when delete button is clicked
 */
export default function FileCard({ file, onClick, onDelete }) {
  const [size, setSize] = useState("0 KB");

  const convert = async (size) => {
    const res = await formatFileSize(size);

    return res;
  };

  useEffect(() => {
    convert(file.size).then((size) => {
      setSize(size);
    });
  }, [file.size]);

  return (
    <div
      onClick={onClick}
      className="relative group flex items-center p-3 bg-white rounded-md shadow hover:shadow-lg cursor-pointer transition-shadow duration-200  border border-1 border-gray-200"
    >
      <div className="flex-shrink-0 mr-2">
        {String(file.type).includes("image") ? (
          <img className="w-12 h-12" src={file.url} alt="" />
        ) : (
          <PaperClipIcon className="h-8 w-8 text-blue-500" />
        )}
      </div>
      <div className="flex-1">
        <p className="text-sm font-medium text-gray-700 overflow-hidden whitespace-pre-wrap mt-0.5 line-clamp-2">
          {file.name}
          <span className="text-xs text-gray-300 group-hover:block hidden capitalize">
            {file.type}
          </span>
        </p>

        {/* Optional: Display file size or type */}
        <p className="text-xs text-gray-400">{size}</p>
      </div>

      {/* <button className="invisible group-hover:visible ml-2 " target="_blank">
        <ArrowDownTrayIcon className="w-3 h-3" />
      </button> */}

      {/* Delete button (if onDelete is provided) */}
      {onDelete && (
        <button
          onClick={(e) => {
            e.stopPropagation(); // Prevent triggering onClick
            onDelete(file.id);
          }}
          className="invisible group-hover:visible ml-2 text-red-500 hover:text-red-600"
          title="Delete file"
        >
          ✕
        </button>
      )}
    </div>
  );
}
