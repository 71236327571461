import React, { useEffect, useState, useTransition } from "react";
import Blogs from "../../components/BlogsComponent";
import Link from "../../services/multi-lang/components/linkWithLang";
import MockUp from "../../components/MockUp";
import ScrollContent from "../../components/ScrollingContent";
import ContentTab from "../../components/ContentTabs";
import ContentTabMobile from "../../components/ContentTabsMobile";
import { StarIcon } from "@heroicons/react/20/solid";
import Pricing from "../../components/pricing/Index";
import { useParams, useSearchParams } from "react-router-dom";
import BlogManager from "../../services/cms/functions";
import Content from "../../services/cms/components/Content";
import moment from "moment";
import Layout from "../../components/layout/landing/Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  ChatBubbleOvalLeftEllipsisIcon,
  HeartIcon,
  PencilSquareIcon,
  TrashIcon,
  LightBulbIcon,
  CheckIcon,
} from "@heroicons/react/24/outline";
import { useLanguage } from "../../services/multi-lang/context/Translator";

const testies = [
  {
    name: "Vahdettin Akkaya",
    title: "Dil ve Konuşma Terapisti",
    text: "Crocodil’i kullanmaya başladığımdan beri çok daha iyi bir terapistim. Crocodil dil ve konuşma terapisi becerilerimde gözle görülür bir ilerleme kaydettim. Hem çocuklar hem de yetişkinler için etkili terapi desteği veren bu uygulama uzmanlar tarafından hazırlanmış içerikleri sayesinde güvenle kullanabilirsiniz. Eğer dil ve konuşma terapisi konusunda yardımcı olacak bir uygulama arıyorsanız, Crocodil kesinlikle tavsiye ederim. Beş yıldızı sonuna kadar hak ediyor.",
  },
  {
    name: "Selen Aslı Göl",
    title: "Dil ve Konuşma Terapisti",
    text: "Crocodil'e geçmeden önce danışan takibini defterle yapıyordum ve her şey çok dağınıktı. Şimdi hem seans notlarımı düzenli tutabiliyorum hem de ödevlendirme kısmı işimi inanılmaz kolaylaştırdı. Materyallere tek tıkla ulaşmak terapi hazırlık süresini neredeyse yarıya indirdi diyebilirim. Gerçekten tam bir terapi asistanı!",
  },
  {
    name: "Samet Can Çankaya",
    title: "Dil ve Konuşma Terapisti",
    text: "Crocodil, terapi süreçlerimi düzenlememde en büyük yardımcım oldu. Danışanlarımın ilerlemesini takip etmek, terapi notlarımı kaydetmek ve her danışan için ayrı bir gelişim süreci oluşturmak çok daha sistematik hale geldi. Özellikle danışan transferi özelliği sayesinde farklı terapistlerle iş birliği yapmak ve süreci kesintisiz sürdürmek mümkün oluyor. Ayrıca, ödevlendirme sistemi sayesinde seans dışında da danışanlarımın gelişimini destekleyebiliyorum. Artık her şeyi tek bir platform üzerinden kolayca yönetebildiğim için çok daha verimli çalışıyorum.",
  },
  {
    name: "Ali Aksakal",
    title: "Dil ve Konuşma Terapisti",
    text: "Crocodil, danışanlarımın ilerlemesini takip etmemi çok daha sistematik hale getirdi. Terapi kayıtlarımı kaybolmadan saklayabiliyor, her danışan için özel materyal arşivimi düzenli tutabiliyorum. Özellikle platformun kullanıcı dostu olması, seans öncesinde vakit kaybetmemi önlüyor. Dijitalleşme sürecinde böyle bir sistemin varlığı büyük bir rahatlık sağladı.",
  },
  {
    name: "Büşra Ünal Erdoğan",
    title: "Dil ve Konuşma Terapisti",
    text: "Crocodil, danışan kayıt takibinde inanılmaz bir kolaylık sağladı. Seans notlarımı düzenli bir şekilde kaydedebiliyor ve her danışanın gelişimini detaylı olarak takip edebiliyorum. Tüm kayıtlarımın güvenli bir şekilde saklanması ve istediğim zaman erişebilmem, terapi süreçlerimi çok daha verimli hale getirdi. Özellikle uzun vadeli takip gerektiren danışanlar için Crocodil büyük bir kolaylık sağlıyor. Artık seanslarım arasında kaybolan notlar, eksik bilgiler gibi sorunlarla uğraşmak zorunda kalmıyorum.",
  },
  {
    name: "Şüheda Güngör",
    title: "Dil ve Konuşma Terapisti",
    text: "Dil ve konuşma terapistleri için tasarlanmış böyle kapsamlı bir platform uzun zamandır eksikliğini hissettiğimiz bir şeydi. Materyallere kolay erişim sağlaması ve terapileri sistemli şekilde arşivleyebilmemiz büyük bir avantaj. Seanslarımı daha verimli hale getirmek için Crocodil’in sunduğu tüm imkanları aktif olarak kullanıyorum. Kesinlikle meslektaşlarıma öneririm!",
  },
  {
    name: "Beren Ediş",
    title: "Dil ve Konuşma Terapisti",
    text: "Danışan bilgilerinin düzenli bir şekilde saklanması ve sürecin takibi terapi verimliliği açısından çok önemli. Crocodil sayesinde artık her danışanımın geçmiş seans notlarına, ilerleme kayıtlarına ve değerlendirmelerine anında erişebiliyorum. Bu, sadece benim için değil, danışanlarımın gelişimini sürdürülebilir hale getirmek için de büyük bir avantaj. Seanslar sırasında not alıp kaybolan defterler, karışık dosyalar yerine her şeyin tek bir sistemde toplu olması terapi sürecimi çok daha sistemli hale getirdi.",
  },
  {
    name: "Furkan Yaya",
    title: "Dil ve Konuşma Terapisti",
    text: "Crocodil'i kullanmaya başladıktan sonra terapi süreçlerimi çok daha düzenli ve verimli yönetebildiğimi fark ettim. Danışanlarımın kayıtlarını tek bir yerde tutabilmek, seans notlarına hızlıca ulaşabilmek büyük kolaylık sağlıyor. Eskiden defterler, kağıtlar arasında kaybolan bilgiler şimdi her an elimin altında. Özellikle ödevlendirme sistemi sayesinde danışanlarıma seans dışında da destek olabiliyorum. En sevdiğim şeylerden biri ise danışan devretme özelliği; meslektaşlarla iş birliği yapmak çok daha pratik hale geldi. Crocodil kesinlikle terapi sürecimi kolaylaştıran bir platform oldu.",
  },
];

const dots = "/dots.svg";

export default function Blog() {
  const { translate } = useLanguage();
  const { blogId } = useParams();
  const [currentBlog, setCurrentBlog] = useState({});
  var settings = {
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const features = [
    {
      name: "Danışan Takip Sistemi",
      description:
        "Danışanlarınızın geçmiş ve güncel seanslarını kaydedin, ilerleme süreçlerini kolayca takip edin.",
      icon: CheckIcon,
    },
    {
      name: "Ödevlendirme Modülü",
      description:
        "Danışanlarınıza terapi sonrası ödevler atayın ve gelişim süreçlerini kontrol edin.",
      icon: CheckIcon,
    },
    {
      name: "Terapistler Arası İş Birliği",
      description:
        "Danışan devretme özelliği ile meslektaşlarınızla bilgi paylaşımını kolaylaştırın ve terapi süreçlerini kesintisiz sürdürebilirsiniz.",
      icon: CheckIcon,
    },
    {
      name: "Dijital Materyal Arşivi",
      description:
        "Özel terapi materyallerinizi düzenli bir şekilde saklayın, ihtiyacınıza göre hızlıca erişin.",
      icon: CheckIcon,
    },
    {
      name: "Güvenli ve Kolay Kullanım",
      description:
        "Crocodil, kullanıcı dostu arayüzü ve güçlü güvenlik altyapısı ile verilerinizi güvenli bir şekilde saklar.",
      icon: CheckIcon,
    },
    {
      name: "Crocodil ile Dijital Çağa Ayak Uydurun!",
      description:
        "Terapilerinizi organize etmek, danışan süreçlerini iyileştirmek ve meslektaşlarınızla daha güçlü bir iş birliği sağlamak için Crocodil’i hemen keşfedin!",
      icon: CheckIcon,
    },
  ];

  const getBlogData = async () => {
    const _blog = await BlogManager.getBlog(blogId);

    console.log("Author", _blog.data);
    setCurrentBlog(_blog.data);
  };

  useEffect(() => {
    getBlogData();
  }, []);

  return (
    <Layout>
      <div className="bg-white">
        <div id="hero">
          <div className="relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20 pt-14">
            <div
              aria-hidden="true"
              className="absolute sm:block hidden inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-teal shadow-xl shadow-black ring-1 ring-teal-50 sm:-mr-80 lg:-mr-96"
            />
            <div className="mx-auto max-w-7xl px-6 py-20 sm:py-40 lg:px-8">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-8 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
                <h1 className="sm:max-w-2xl text-balance text-4xl font-semibold tracking-tight text-gray-900 sm:text-6xl lg:col-span-2 xl:col-auto">
                  {translate("HERO_Title_1")} <br /> {translate("HERO_Title_2")}{" "}
                  <span className="opacity-70 text-teal-600 text-4xl sm:text-5xl">
                    {translate("HERO_Title_1_Bold")}
                  </span>
                </h1>
                <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
                  <p className="text-pretty font-medium text-gray-500 sm:text-base sm:pl-2">
                    {translate("HERO_Paragraph")}
                  </p>
                  <div className="mt-10 flex items-center gap-x-6 sm:pl-1">
                    <Link
                      to="register"
                      className="rounded-md bg-teal-600 sm:w-auto w-full text-center px-20 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                    >
                      {translate("Kayıt Ol")}
                    </Link>
                    {/* <a
                      href="#"
                      className="text-sm/6 font-semibold text-teal-700"
                    >
                      Daha Fazla Bilgi <span aria-hidden="true">→</span>
                    </a> */}
                  </div>
                </div>
                <img
                  alt=""
                  src="/hero-photo.png"
                  className="mt-10 aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36"
                />
              </div>
            </div>
            <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
          </div>
        </div>

        <Slider {...settings}>
          {testies.map((testimonial, index) => (
            <div className="flex items-center justify-center w-full ">
              <section
                key={index}
                className="px-6 sm:py-12 py-8 flex items-center justify-center  flex-row bg-teal-500"
              >
                <figure className="max-w-5xl">
                  <div className="sm:text-sm text-base">
                    <div className="font-semibold text-white text-base">
                      {testimonial.name}
                    </div>
                    <div className="mt-0.5 sm:text-sm text-xs text-white">
                      {testimonial.title}
                    </div>
                  </div>
                  <div className="flex gap-x-1 mt-2 text-yellow-200">
                    {[...Array(5)].map((_, starIndex) => (
                      <StarIcon key={starIndex} className="size-5 flex-none" />
                    ))}
                  </div>
                  <blockquote className="mt-3 font-semibold text-white">
                    <p className="sm:text-xl text-base">{testimonial.text}</p>
                  </blockquote>
                </figure>
              </section>
            </div>
          ))}
        </Slider>

        <MockUp />

        <div>
          <ScrollContent />
        </div>

        <div id="features" className="bg-white py-24 sm:py-40">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-16 sm:gap-y-20 lg:mx-0 lg:max-w-none  lg:grid-cols-5">
              {/* <div className="col-span-2">
                <img
                  className="rounded-lg shadow-2xl shadow-teal-500 sm:w-[600px] sm:h-[970px] object-fill border border-gray-300"
                  src="https://cdn.prod.website-files.com/6572fdb617211ce3d9d76878/660239cafa171b33904a0be0_181.jpg"
                  alt=""
                />
              </div> */}
              <dl className="col-span-5 grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 items-center pb-5">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-4 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8 col-span-full">
                  <h1 className="max-w-2xl text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl lg:col-span-2 xl:col-auto">
                    <span className="text-teal-600">
                      {" "}
                      Konuşma Terapistleri İçin
                    </span>{" "}
                    Dijital Asistan
                  </h1>
                  <p className="text-pretty text-lg font-medium text-gray-500 sm:text-xl/8 sm:mt-0 mt-3">
                    Crocodil ile terapi süreçlerinizi daha düzenli, verimli ve
                    etkili yönetin. Danışan takibinden ödevlendirmeye kadar tüm
                    ihtiyacınız olan araçlar tek bir platformda!
                  </p>
                </div>

                {features.map((feature) => (
                  <div key={feature.name}>
                    <dt className="text-base/7 font-semibold text-gray-900">
                      <div className="sm:mb-6 mb-3 flex sm:size-10 size-8 items-center justify-center rounded-lg bg-teal-600">
                        <feature.icon
                          aria-hidden="true"
                          className="sm:size-6 size-5 text-white"
                        />
                      </div>
                      {feature.name}
                    </dt>
                    <dd className="mt-1 text-base/7 text-gray-600">
                      {feature.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>

        <div className="bg-teal-600 w-full">
          <div className="mx-auto sm:block hidden max-w-2xl px-6 lg:max-w-7xl lg:px-8 py-20">
            <ContentTab />
          </div>
          <div className="mx-auto max-full sm:hidden block lg:max-w-7xl lg:px-8 py-10">
            <ContentTabMobile />
          </div>
        </div>

        <Pricing />

        {/* <Blogs /> */}
      </div>
    </Layout>
  );
}
