import React from "react";

export default function Loading() {
  return (
    <div className="h-screen w-full flex items-center justify-center">
      <img
        src="/logo.png"
        className="sm:w-52 animate-pulse sm:h-52 w-16 h-16"
        alt=""
      />
    </div>
  );
}
