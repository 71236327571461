import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import mammoth from "mammoth";
import { PaperClipIcon } from "@heroicons/react/24/outline";

import { EmbedPDF } from "@simplepdf/react-embed-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;
/**
 * FilePreview Component
 * @param {Object} props
 * @param {Object} props.file - File object with name, type, url, etc.
 * @param {Function} props.onClose - Callback to close the preview
 */
export default function FilePreview({ file, onClose }) {
  const [docxContent, setDocxContent] = useState("");

  useEffect(() => {
    if (!file) return;

    console.log("File", file);
    if (
      file.type ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      fetch(file.url)
        .then((response) => response.arrayBuffer())
        .then((arrayBuffer) => mammoth.extractRawText({ arrayBuffer }))
        .then((result) => setDocxContent(result.value))
        .catch((error) => console.error("Error loading DOCX:", error));
    }
  }, [file]);

  return (
    <div className="w-full overflow-hidden">
      <button
        onClick={onClose}
        className="absolute top-2 right-2 text-gray-600 hover:text-red-500 text-xl"
      >
        ✕
      </button>

      {file?.type !== "application/pdf" && (
        <div className="flex items-center mb-3">
          <PaperClipIcon className="h-6 w-6 text-blue-500 mr-2" />
          <p className="text-lg font-medium text-gray-800 truncate">
            {file?.name}
          </p>
        </div>
      )}

      <div className="overflow-hidden max-h-[80vh]">
        {file?.type === "application/pdf" ? (
          <div className="flex justify-center">
            <EmbedPDF
              mode="inline"
              style={{ width: "100%", height: "100dvh" }}
              documentURL={file.url}
            />
          </div>
        ) : file?.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
          <div className="p-3 bg-gray-100 text-gray-800 rounded">
            <p className="whitespace-pre-wrap">{docxContent || "Loading..."}</p>
          </div>
        ) : String(file?.type).includes("image") ? (
          <div className="flex justify-center">
            <img
              src={file.url}
              alt={file.name}
              className="max-w-full max-h-[500px] rounded-lg shadow"
            />
          </div>
        ) : (
          <p className="text-red-500 text-center">
            {String(file?.type)}
            Preview not available for this file type.
          </p>
        )}
      </div>
    </div>
  );
}
