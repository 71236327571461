import { useState, useEffect, useRef } from "react";

const texts = [
  {
    id: 1,
    text: "Hakkımızda",
    p: `Crocodil, dil ve konuşma terapistlerinin terapi süreçlerini daha verimli yönetmelerini sağlamak için geliştirilmiş yenilikçi bir dijital terapi asistanıdır. Terapistlerin danışan kayıtlarını düzenli tutmalarına, terapi notlarını güvenle saklamalarına ve terapi süreçlerini sistematik bir şekilde yönetmelerine yardımcı olur. \n\n Ödevlendirme, danışan takip ve terapistler arası iş birliği gibi özelliklerle, seans öncesi ve sonrası süreci kolaylaştırarak terapistlerin iş yükünü hafifletiyoruz. Crocodil, sadece bir araç değil, terapi süreçlerinde en büyük destekçinizdir.`,
    bgColor: {
      bg: "rgba(255,255,255)",
      text: "rgba(15, 118, 110)",
    },
  },
  {
    id: 2,
    text: "Misyonumuz",
    p: `Dil ve konuşma terapistlerine terapi süreçlerini daha etkili yönetebilecekleri, danışanlarının gelişimlerini takip edebilecekleri ve seans dışı etkileşimleri artırabilecekleri güçlü bir platform sunmak.\n\nCrocodil olarak, terapistlerin işlerini kolaylaştıran, güvenilir ve kullanıcı dostu bir sistem oluşturarak terapilerin daha verimli ve sistematik bir şekilde ilerlemesini sağlamak için çalışıyoruz.`,
    bgColor: {
      text: "rgba(255,255,255)",
      bg: "rgba(15, 118, 110)",
    },
  },
  {
    id: 3,
    text: "Vizyonumuz",
    p: `Terapistlerin dijital dönüşümüne liderlik eden, en güvenilir ve kapsamlı terapi asistanı olmak.\n\nDil ve konuşma terapistlerinin tüm süreçlerini tek bir platformda yönetebileceği bir sistem oluşturmak.
Terapistlerin danışanlarıyla daha verimli çalışmasını sağlamak ve terapi süreçlerini teknolojiyle destekleyerek daha iyi sonuçlar almalarına katkıda bulunmak.
Türkiye’de ve uluslararası alanda en çok tercih edilen dijital terapi asistanı olmak.
Crocodil ile tanışın, terapi süreçlerinizi kolaylaştırın ve danışanlarınıza daha fazla odaklanın! 🚀`,
    bgColor: {
      bg: "rgba(255,255,255)",
      text: "rgba(15, 118, 110)",
    },
  },
];

export default function ImageTextGrid() {
  const [activeIndex, setActiveIndex] = useState(0);
  const containerRef = useRef(null);
  const [bgColor, setBgColor] = useState(texts[0].bgColor);

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current) return;

      const items = Array.from(
        containerRef.current.querySelectorAll(".scroll-item")
      );
      let closestIndex = 0;
      let minDiff = Infinity;
      const screenCenter = window.innerHeight / 2;

      items.forEach((item, index) => {
        const rect = item.getBoundingClientRect();
        const itemCenter = rect.top + rect.height / 2;
        const diff = Math.abs(itemCenter - screenCenter);

        if (diff < minDiff) {
          minDiff = diff;
          closestIndex = index;
        }
      });

      setActiveIndex((prevIndex) => {
        if (prevIndex !== closestIndex) {
          setBgColor(texts[closestIndex].bgColor); // Update color smoothly
        }
        return closestIndex;
      });
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      className="w-full px-8 gap-8 h-full transition-colors duration-[1000ms] ease-in-out"
      style={{ backgroundColor: bgColor.bg }}
    >
      {/* Scrollable Text Area */}
      <div
        className="w-full flex sm:p-10 sm:my-10 max-w-6xl mx-auto"
        ref={containerRef}
      >
        {/* Text List (2/5) */}
        <div className="w-full flex flex-col justify-center items-center space-y-[30vh] sm:mt-[25vh] my-20 sm:mb-[25vh]">
          {texts.map((item, index) => {
            const opacity = Math.max(
              0.2,
              1 - Math.abs(index - activeIndex) * 0.4
            );

            return (
              <div
                key={item.id}
                className="scroll-item text-left text-2xl transition-opacity duration-500"
                style={{ opacity }}
              >
                <p
                  className="text-6xl tracking-wide mb-7 font-bold"
                  style={{ color: bgColor.text }}
                >
                  {item.text}
                </p>
                <p
                  className="text-base whitespace-pre-line tracking-wide opacity-95"
                  style={{ color: bgColor.text }}
                >
                  {item.p}
                </p>
              </div>
            );
          })}
        </div>

        {/* Image Section (3/5) - Sticky Image in a Scrolling Parent */}
        {/* <div className="w-2/5 relative h-full bg-red-500 flex items-start">
          <div className="sticky top-0 flex items-center justify-center h-screen w-full">
            <img
              src={texts[activeIndex].image}
              alt="Active"
              className="w-64 h-64 object-cover rounded-lg shadow-lg transition-opacity duration-300 ease-in-out"
            />
          </div>
        </div> */}
      </div>
    </div>
  );
}
