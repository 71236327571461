import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import BlogManager from "../../services/cms/functions";
import Content from "../../services/cms/components/Content";
import moment from "moment";
import Layout from "../../components/layout/landing/Layout";
import { useLanguage } from "../../services/multi-lang/context/Translator";

export default function Blog() {
  const { blogId } = useParams();
  const { translate } = useLanguage();
  const [currentBlog, setCurrentBlog] = useState({});

  const getBlogData = async () => {
    const _blog = await BlogManager.getBlog(blogId);

    console.log("Author", _blog.data);
    setCurrentBlog(_blog.data);
  };

  useEffect(() => {
    getBlogData();
  }, []);

  return (
    <Layout>
      <div className="bg-white px-6 py-32 lg:px-8">
        <div className="mx-auto sm:max-w-3xl p-3">
          <h1 className="text-pretty font-semibold tracking-tight text-gray-900 sm:text-5xl">
            {currentBlog?.title}
          </h1>
          <div className="relative flex items-center gap-2 my-2">
            <img
              alt=""
              src={currentBlog.author?.photoUrl}
              className="size-10 object-fit border border-1 border-gray-300 shadow rounded-full bg-gray-50"
            />
            <div className="mb-1">
              <p className="text-sm/6 text-gray-600">
                {currentBlog.author?.firstName +
                  " " +
                  currentBlog.author?.lastName}
              </p>
              <p className="text-xs text-gray-500">
                {translate("Yayın Tarihi")} :{" "}
                {moment(currentBlog?.created_at?.toDate()).format("DD/MM/yyyy")}
              </p>
            </div>
          </div>
          <p className="mt-2 text-lg my-3 text-gray-600 ">
            {currentBlog?.description}
          </p>

          <hr className="mt-2" />
          <Content data={currentBlog.content} />
        </div>
      </div>
    </Layout>
  );
}
