import React, { useEffect, useState } from "react";
import Editor from "../components/Editor Edit";
import { TextInput, TextareaInput } from "../../../components/inputs/Index";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEditor } from "../hooks/useEditor";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { storage } from "../../../firebaseConfig"; // Adjust path as per your project structure
import { useAuth } from "../../../context/AuthContext";
import BlogManager from "../functions";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useLangNavigation } from "../../multi-lang/components/navigateWithLang";

function FileExplorer() {
  const { currentUser } = useAuth();
  const { blogId } = useParams();
  const { editorContent, setEditorContent } = useEditor();
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const navigate = useLangNavigation();

  const formik = useFormik({
    initialValues: {
      title: "Yeni Blogunuz",
      description: "",
      coverURL: "",
      status: "pending",
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required("Blog ismi zorunludur.")
        .min(3, "Blog ismi en az 3 karakter olmalıdır.")
        .max(100, "Blog ismi en fazla 100 karakter olabilir."),
    }),
    onSubmit: (values) => {
      values.content = editorContent;
      values.authorID = currentUser.uid;
      BlogManager.updateBlog(blogId, values).then((res) => {
        if (res.success) {
          Swal.fire({
            title: "Updated",
          });
          navigate("/blog-manager");
        } else {
          Swal.fire({
            title: "Couldn't Update",
          });
        }
      });
    },
  });

  const setStatusDraft = () => {
    formik.setFieldValue("status", "draft");
  };

  const handleImageUpload = async (file) => {
    if (!file) return;

    const storageRef = ref(storage, `blog_covers/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    setUploading(true);
    setUploadProgress(0);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      (error) => {
        console.error("Upload error:", error);
        setUploading(false);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        console.log("Download", downloadURL);
        formik.setFieldValue("coverURL", downloadURL);
        setUploading(false);
      }
    );
  };

  const getBlogData = async () => {
    const blogData = await BlogManager.getBlog(blogId);

    return blogData;
  };

  useEffect(() => {
    getBlogData().then((data) => {
      formik.setValues({
        ...formik.values,
        ...data.data,
      });
    });
  }, [blogId]);

  return (
    <div className="p-4 bg-white m-5 rounded-md border border-1 border-gray-300 shadow-sm pt-5 pb-10">
      <form
        onSubmit={formik.handleSubmit}
        className="w-full grid grid-cols-2 gap-5"
      >
        <div>
          <TextInput
            label="Blog İsmi"
            value={formik.values.title}
            name="title"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.title && formik.errors.title && (
            <p className="text-red-500 text-xs mt-1">{formik.errors.title}</p>
          )}

          <br />
          <TextareaInput
            label="Blog Açıklama"
            value={formik.values.description}
            name="description"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <label className="block text-sm font-medium text-gray-700 mt-4">
            Kapak Fotoğrafı Yükle
          </label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleImageUpload(e.target.files[0])}
            className="mt-2 border p-2 w-full rounded-md"
          />

          {uploading && (
            <p className="text-blue-500 text-xs mt-2">
              Yükleniyor... %{uploadProgress.toFixed(0)}
            </p>
          )}

          {formik.values.coverURL && (
            <img
              src={formik.values.coverURL}
              alt="Cover"
              className="mt-3 w-48 h-32 object-cover rounded-md border"
            />
          )}
        </div>

        <div className="mt-auto ml-auto flex items-center gap-3">
          <button
            type="submit"
            onClick={setStatusDraft}
            className="bg-gray-500 text-white p-2.5 h-max mt-auto rounded-md w-max ml-auto"
          >
            Save as Draft
          </button>

          <button
            type="submit"
            className="bg-teal-500 text-white p-2.5 h-max mt-auto rounded-md w-max ml-auto"
          >
            Bu Blogu Kaydet
          </button>
        </div>
      </form>

      <Editor />
    </div>
  );
}

export default FileExplorer;
