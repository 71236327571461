import { create } from "zustand";
import { StorageService } from "../functions/index"; // Your existing service

const storageService = new StorageService();

export const useFileExplorerStore = create((set, get) => ({
  // ----------------------------
  // State
  // ----------------------------
  currentPath: "root",
  folders: [],
  files: [],
  isLoading: false,
  error: null,

  // ----------------------------
  // Actions
  // ----------------------------

  /**
   * Loads the folders and files for a given path,
   * updating state accordingly.
   * @param {string} path
   */
  loadItems: async (path) => {
    try {
      set({ isLoading: true, error: null });

      // Fetch both folders and files in parallel
      const [folders, files] = await Promise.all([
        storageService.listFolders(path),
        storageService.listFiles(path),
      ]);

      set({
        folders,
        files,
        currentPath: path,
        isLoading: false,
      });
    } catch (err) {
      set({
        error: err.message || "Failed to load items.",
        isLoading: false,
      });
    }
  },

  /**
   * Navigates to a subfolder of the current path.
   * @param {string} folderName - The name of the folder to go into
   */
  goToFolder: (folderName) => {
    const { currentPath, loadItems } = get();
    // Build a new path (e.g., 'root/MyFolder')
    const newPath = currentPath ? `${currentPath}/${folderName}` : folderName;

    loadItems(newPath);
  },

  /**
   * Navigates one level up from the current path,
   * unless we are already at 'root'.
   */
  goBack: () => {
    const { currentPath, loadItems } = get();

    if (!currentPath || currentPath === "root") {
      // Already at root, can't go back further
      return;
    }

    // Remove the last folder segment
    const lastSlashIndex = currentPath.lastIndexOf("/");
    const newPath =
      lastSlashIndex > -1 ? currentPath.substring(0, lastSlashIndex) : "root";

    loadItems(newPath);
  },

  /**
   * Creates a new folder under the current path,
   * then reloads the list.
   * @param {string} folderName
   */
  createFolder: async (folderName, isPublic) => {
    try {
      set({ isLoading: true, error: null });

      const { currentPath, loadItems } = get();
      await storageService.createFolder(folderName, currentPath, isPublic);

      // Refresh the items
      await loadItems(currentPath);
    } catch (err) {
      set({
        error: err.message || "Failed to create folder.",
      });
    } finally {
      set({ isLoading: false });
    }
  },

  /**
   * Uploads a file (File object from an <input type="file" />)
   * to Firebase Storage under the current path,
   * then reloads the list.
   * @param {File} file
   */
  createFile: async (file, isPublic) => {
    try {
      set({ isLoading: true, error: null });

      const { currentPath, loadItems } = get();
      await storageService.createFile(file, currentPath, isPublic);

      // Refresh the items
      await loadItems(currentPath);
    } catch (err) {
      set({
        error: err.message || "Failed to upload file.",
      });
    } finally {
      set({ isLoading: false });
    }
  },

  /**
   * Deletes a folder by ID, then reloads the list.
   * Note: Your StorageService may NOT automatically
   * delete subfolders/files—handle that as needed.
   * @param {string} folderId
   */
  deleteFolder: async (folderId) => {
    try {
      set({ isLoading: true, error: null });

      const { currentPath, loadItems } = get();
      await storageService.deleteFolder(folderId);

      // Reload current path
      await loadItems(currentPath);
    } catch (err) {
      set({
        error: err.message || "Failed to delete folder.",
      });
    } finally {
      set({ isLoading: false });
    }
  },

  /**
   * Deletes a file by ID, then reloads the list.
   * @param {string} fileId
   */
  deleteFile: async (fileId) => {
    try {
      set({ isLoading: true, error: null });

      const { currentPath, loadItems } = get();
      await storageService.deleteFile(fileId);

      // Reload current path
      await loadItems(currentPath);
    } catch (err) {
      set({
        error: err.message || "Failed to delete file.",
      });
    } finally {
      set({ isLoading: false });
    }
  },
}));
