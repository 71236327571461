import React from "react";
import Swal from "sweetalert2";
import { PhoneIcon } from "@heroicons/react/24/outline";
import { useLanguage } from "../../../services/multi-lang/context/Translator";
import Navbar from "./elements/Navbar";

export default function Layout({ children }) {
  const { translate } = useLanguage();
  const showAlert = (title, text) => {
    Swal.fire({
      title: title,
      html: text,
      icon: "info",
      confirmButtonText: "Tamam",
    });
  };

  return (
    <>
      <Navbar />
      <main className="overflow-hidden">{children}</main>
      <footer className="bg-teal-800">
        <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="mb-6 md:mb-0">
              <a href="https://flowbite.com/" className="flex items-center">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/crocodil-9f989.appspot.com/o/Logos%2Flogo-v.png?alt=media&token=beb46bbe-5da9-41d8-b79d-5aca53dfc81c"
                  className="h-8 me-3"
                  alt="FlowBite Logo"
                />
              </a>
            </div>
            <div className="flex mt-4 sm:justify-center sm:mt-0 gap-x-5">
              <label
                htmlFor="userAgreement"
                className="ml-2 text-sm font-medium text-white"
              >
                <span
                  className="text-white hover:underline cursor-pointer"
                  onClick={() =>
                    showAlert(
                      "KVKK Aydınlatma Metni",
                      `
                        <h3 class="font-bold">Kullanıcı Sözleşmesi</h3>
                        <p><strong>1) Veri Sorumlusunun Kimliği:</strong> crocodil.com.tr kişisel verilerinizi KVKK kapsamında işlediğini bildirir.</p>
                        <p><strong>2) İşlenen Kişisel Veri Kategorileri:</strong> Kimlik, iletişim, müşteri işlem, pazarlama, işlem güvenliği, hukuki işlem, sağlık bilgileri, finans.</p>
                        <p><strong>3) Kişisel Verilerin İşlenme Sebepleri ve Amaçları:</strong> Kanunlarda öngörülmesi, hukuki yükümlülükler, hizmet sunumu, güvenlik, pazarlama.</p>
                        <p><strong>4) Açık Rıza ile İşlenen Veriler:</strong> Sağlık ve pazarlama verileri.</p>
                      `
                    )
                  }
                >
                  {translate("KVKK VE AYDINLATMA METNİ")}
                </span>
              </label>
              <label
                htmlFor="privacyPolicy"
                className="ml-2 text-sm font-medium text-white"
              >
                <span
                  className="text-white hover:underline cursor-pointer"
                  onClick={() =>
                    showAlert(
                      "Tersms & Conditions",
                      `
                        <h3 class="font-bold">Gizlilik Politikası</h3>
                        <p><strong>1) Kişisel Verilerin İşlenmesi:</strong> Kişisel verileriniz sadece izin verdiğiniz şekilde işlenir ve saklanır.</p>
                        <p><strong>2) Veri Güvenliği:</strong> Kişisel verilerinizin güvenliği için gerekli teknik ve idari tedbirler alınır.</p>
                        <p><strong>3) Veri Paylaşımı:</strong> Kişisel verileriniz, yalnızca hukuki zorunluluklar ve sizin onayınız doğrultusunda üçüncü kişilerle paylaşılır.</p>
                        <p><strong>4) Haklarınız:</strong> Kişisel verilerinize erişim, düzeltme, silme ve itiraz etme haklarınız mevcuttur. </p> 
                      `
                    )
                  }
                >
                  {translate("Terms & Conditions")}
                </span>
              </label>
            </div>
          </div>
          <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
          <div className="sm:flex sm:items-center sm:justify-between">
            <span className="text-sm text-white sm:text-center">
              © 2024 Crocodil, Tüm Hakları Saklıdır.
            </span>

            <span className="text-sm text-white sm:text-center mt-4 sm:mt-0 ">
              Destek hattı:{" "}
              <a href="mailto:info@crocodil.com.tr" className="hover:underline">
                info@crocodil.com.tr
              </a>
            </span>

            <span className="text-sm text-white sm:text-center mt-4 sm:mt-0  ">
              <a
                href="mailto:info@crocodil.com.tr"
                className="hover:underline flex items-center gap-1"
              >
                <PhoneIcon className="w-5 font-light stroke-1" /> +90 541 329 65
                54
              </a>
            </span>

            <span className="text-base text-white sm:text-center mt-4 sm:mt-0 ">
              <a
                href="mailto:info@crocodil.com.tr"
                className="hover:underline flex items-center gap-1"
              >
                <img
                  src="/wp.svg"
                  alt="whatsapp"
                  className="sm:w-8 w-5 sm:mt-0 mt-2 sm:pb-0 pb-2"
                />{" "}
                +90 541 329 65 54
              </a>
            </span>

            <div className="flex mt-4 sm:justify-center sm:mt-0">
              <a
                href="https://www.instagram.com/crocodil.tr/"
                className="text-white hover:text-gray-900 dark:hover:text-white ms-5"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                </svg>
              </a>
              <a
                href="https://www.linkedin.com/company/crocodil-tr/"
                className="text-white hover:text-gray-900 dark:hover:text-white ms-5"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
